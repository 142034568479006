import { SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useUserSelfDetails } from '@steelbuy/data-access';
import { ListingDetailsExpirationView } from '@steelbuy/ui-domain';
import { InputCheckbox, CustomLink } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

type UpdateListingCheckboxesProps = {
    agreedAuthorised: boolean;
    agreedToTAndC: boolean;
    isRenewable: boolean;
    DetailsExpirationViewMode: ListingDetailsExpirationView;
    setAgreedAuthorised: (value: SetStateAction<boolean>) => void;
    setAgreedToTAndC: (value: SetStateAction<boolean>) => void;
};

export const UpdateListingCheckboxes = ({
    agreedAuthorised,
    agreedToTAndC,
    isRenewable,
    DetailsExpirationViewMode,
    setAgreedAuthorised,
    setAgreedToTAndC,
}: UpdateListingCheckboxesProps) => {
    const { t } = useTranslation(['translation']);
    const { data: userData } = useUserSelfDetails();

    return (isRenewable || DetailsExpirationViewMode === ListingDetailsExpirationView.LIVE_EDITABLE) && userData ? (
        <>
            <InputCheckbox
                label={
                    <>
                        {' '}
                        {t('translation:application.checkout.checkbox1Label', {
                            organisationName: userData?.organisation?.name || 'my organisation',
                        })}
                    </>
                }
                checked={agreedAuthorised}
                onChange={(checked) => setAgreedAuthorised(checked)}
                data-testid="authorised-checkbox"
                isMandatory
            />

            <InputCheckbox
                label={
                    <>
                        {' '}
                        <Trans
                            i18nKey="translation:application.sellerListingDetails.checkboxLabel"
                            components={{
                                websitetncs: <CustomLink path={RoutePath.TERMS_WEBSITE} isInlineLink openInNewTab />,
                                sellertncs: <CustomLink path={RoutePath.TERMS_SELLER} isInlineLink openInNewTab />,
                            }}
                            t={t}
                            values={{ organisationName: userData?.organisation?.name }}
                        />
                    </>
                }
                checked={agreedToTAndC}
                onChange={() => setAgreedToTAndC(!agreedToTAndC)}
                data-testid="tncs-checkbox"
                isMandatory
            />
        </>
    ) : null;
};
