import { useTranslation } from 'react-i18next';
import { CartProductType } from '@steelbuy/data-access';
import {
    formatCurrency,
    CartEntryModel,
    AnyMaterialModel,
    Product,
    formatNumber,
    formatNumberForDimensions,
    getCurrencyFromRegion,
    getRegion,
} from '@steelbuy/ts-shared';

import { CartSummaryHeader } from '../cart-summary/cart-summary-header/CartSummaryHeader';
import { useMaterialProperties } from '../material/Material';

import './CheckoutCartSummary.scss';

export type CheckoutCartSummaryProps = {
    cartData: CartEntryModel;
};

export const CheckoutCartSummary = ({ cartData }: CheckoutCartSummaryProps) => {
    const { t } = useTranslation(['domainModel', 'uiDomain']);

    const isPackage = cartData.productType === CartProductType.PACKAGE;
    const material = cartData.product.material as AnyMaterialModel;
    const materialTypeProperty = isPackage ? cartData.product.material : cartData.product.material.materialType;

    const materialProperties = useMaterialProperties(isPackage ? cartData.product : material, ['materialType']);

    const materialDimensions = isPackage
        ? { thickness: undefined, width: undefined }
        : cartData.product.materialDimensions;

    const { thickness, width } = materialDimensions;

    const length = 'length' in materialDimensions ? formatNumberForDimensions(materialDimensions.length) : undefined;

    const dimensionsText = [
        thickness ? `${formatNumberForDimensions(thickness)}mm` : '',
        width ? `x ${formatNumberForDimensions(width)}mm` : '',
        length ? `x ${length}mm` : '',
    ]
        .filter(Boolean)
        .join(' ');

    const materialType = t(`material.materialType.value.${materialTypeProperty}`);

    const tolerance = material.tolerance ? t(`material.tolerance.value.${material.tolerance}`) : '';

    const productType = isPackage
        ? t(`domainModel:material.product.value.${Product.PACKAGE}`)
        : t(`domainModel:material.product.value.${cartData.product.material.product}`);

    const weight =
        cartData.product.weight && cartData.product.tradeUnit
            ? t(`material.tradeUnit.value.${cartData.product.tradeUnit}.quantity`, {
                  count: cartData.product.weight,
                  formatted: formatNumber(cartData.product.weight),
              })
            : '';

    const totalExcludingVat = formatCurrency(
        cartData.product.pricing.totalExcludingVat?.value,
        cartData.product.pricing.currencyCode
    );

    const hasOfferPrice = !isPackage && cartData.product.offerPricing;

    const offerPricingTotal =
        hasOfferPrice &&
        formatCurrency(
            cartData.product.offerPricing?.totalExcludingVat.value ?? 0,
            cartData.product.offerPricing?.currencyCode ?? getCurrencyFromRegion(getRegion())
        );

    const oldPricingTotal =
        cartData.product.oldPricing &&
        formatCurrency(
            cartData.product.oldPricing?.totalExcludingVat?.value ?? 0,
            cartData.product.oldPricing?.currencyCode ?? getCurrencyFromRegion(getRegion())
        );

    return (
        <div className="checkout-cart-summary">
            <div className="checkout-cart-summary__header-wraper">
                <CartSummaryHeader
                    symbol={isPackage ? cartData.productType.toLowerCase() : material.product.toLowerCase()}
                    firstLine={materialProperties.map((property) => property.label).join(', ')}
                    secondLine={`${productType}, ${materialType}, ${
                        cartData.product.prime
                            ? t('domainModel:material.quality.value.prime')
                            : t('domainModel:material.quality.value.nonPrime')
                    }, ${weight} `}
                    thirdLine={[dimensionsText, tolerance].filter(Boolean).join(', ')}
                />
                <div className="checkout-cart-summary__header-wraper__pricing">
                    <span className="checkout-cart-summary__header-wraper__total-excluding-pricing__label">
                        {t(`uiDomain:listingBuyerDetailsPrice.totalExclVat`)}:
                    </span>
                    {hasOfferPrice || cartData.product.oldPricing ? (
                        <span className="checkout-cart-summary__header-wraper__total-excluding-pricing__value">
                            <span className="checkout-cart-summary__header-wraper__total-excluding-pricing__value--old">
                                {oldPricingTotal ?? totalExcludingVat}
                            </span>
                            <span className="checkout-cart-summary__header-wraper__total-excluding-pricing__value--new">
                                {offerPricingTotal || totalExcludingVat}
                            </span>
                        </span>
                    ) : (
                        <span className="checkout-cart-summary__header-wraper__total-excluding-pricing__value">
                            {totalExcludingVat}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
