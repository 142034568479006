import { useTranslation } from 'react-i18next';
import { CustomLink } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

export const BuyingTerms = () => {
    const { t } = useTranslation('translation');

    const address = (
        <span>
            <span>{t('constants.primaryAddress.line1')}, </span>
            <span>{t('constants.primaryAddress.line2')}, </span>
            <span>{t('constants.primaryAddress.line3')}, </span>
            <span>{t('constants.primaryAddress.line4')}, </span>
            <span>{t('constants.primaryAddress.line5')}</span>
        </span>
    );

    return (
        <div className="terms-box">
            <h2 id="buyer">AGREED TERMS - BUYER</h2>

            <h3 id="1-about-us">1. About Us</h3>
            <p>
                <span className="sub-section-marker">1.1</span> <strong>Company details</strong>. Steelbuy Ltd (company
                number {t('constants.companyNumber')}) (<strong>We</strong> and <strong>Us</strong>) is a company
                registered in England and Wales and Our registered office is at {address}. Our VAT number is{' '}
                {t('constants.vatNumber')}. We operate the online marketplace{' '}
                <CustomLink path={`https://${t('constants.marketplace')}`} isInlineLink isAnchorLink>
                    {t('constants.marketplace')}
                </CustomLink>{' '}
                (<strong>Our Marketplace</strong>
                ).
            </p>
            <p>
                <span className="sub-section-marker">1.2</span> <strong>Contacting Us</strong>. To contact Us telephone
                Our customer service team at {t('constants.customerServiceTel')} or email{' '}
                <CustomLink path={`mailto:${t('constants.emailSupport')}`} isInlineLink isAnchorLink>
                    {t('constants.emailSupport')}
                </CustomLink>
                . How to give Us formal notice of any matter under the Contract is set out in clause 16.2.
            </p>

            <h3 id="2-our-contract-with-you">2. Our Contract with you</h3>
            <p>
                <span className="sub-section-marker">2.1</span> <strong>Our Contract</strong>. Note that We do not
                provide an intermediation service. These terms and conditions (<strong>Terms</strong>) apply to each
                order by you and supply of Goods by Us (not a third party) to you. Each such order will, when accepted
                in accordance with clause 3.4, constitute a separate Contract between you and Us (
                <strong>Contract</strong>
                ). No other terms are implied by trade, custom, practice or course of dealing. Once a Contract is formed
                between us these terms and conditions will apply to all future purchases of Goods from Us by you.{' '}
            </p>
            <p>
                <span className="sub-section-marker">2.2</span>{' '}
                <strong>Each Contract is DDP (the delivery address specified on the Order) (Incoterms 2020)</strong>{' '}
                except to the extent varied by these Terms. To the extent of any inconsistency between Incoterms 2020
                and these Terms, these Terms shall take precedence.
            </p>
            <p>
                <span className="sub-section-marker">2.3</span> <strong>Entire agreement</strong>. The Contract is the
                entire agreement between us in relation to its subject matter. You acknowledge that you have not relied
                on any statement, promise or representation or assurance or warranty that is not set out in the
                Contract.
            </p>
            <p>
                <span className="sub-section-marker">2.4</span> <strong>Language.</strong> These Terms and the Contract
                are made only in the English language.
            </p>

            <h3 id="3-placing-an-order-and-its-acceptance">3. Placing an Order and its acceptance</h3>
            <p>
                <span className="sub-section-marker">3.1</span> <strong>Placing your Order</strong>. Please follow the
                onscreen prompts on Our Marketplace to place an order (<strong>Order</strong>
                ). Each Order is an offer by you to buy the Goods specified in the Order from Us (<strong>Goods</strong>
                ) subject to these Terms. By placing an Order, you represent and warrant to Us that you are a business,
                acting in the course of your business and are not a consumer. When you place and Order, the Order is
                binding on you and you cannot withdraw it:
            </p>
            <ol>
                <li>
                    before 8 days after midnight GMT on the date on which you receive a Processing Notice from Us; or
                </li>
                <li>if the Offer is accepted in accordance with clause 3.4.</li>
            </ol>
            <p>
                <strong>
                    <span className="sub-section-marker">3.2</span> Correcting input errors.
                </strong>{' '}
                Our order process allows you to check and amend any errors before submitting your Order to Us. Please
                check the Order carefully before confirming it. You are responsible for ensuring that your Order is
                complete and accurate.
            </p>
            <p>
                3.3
                <strong>Order Processing</strong> When you place your Order, We will send you a Processing Notice.
                Sending you a Processing Notice does not mean that We accept your Order. There will be no binding
                Contract between us. A Processing Notice means that We are attempting to process the purchase of the
                Goods by arranging for their collection and transportation.
            </p>
            <p>
                <strong>
                    <span className="sub-section-marker">3.4</span> Accepting your Order.
                </strong>{' '}
                When We have arranged for the collection and transportation of the Goods We will confirm Our acceptance
                of your Order to you by sending you an email that confirms that the Goods have been sold to you (
                <strong>Sale Confirmation</strong>
                ). The Contract between you and Us will only be formed when We send you the Sale Confirmation. The fact
                that you have placed an Order does not mean that We are obliged to (a) send you a Processing Notice; (b)
                send you a Sale Confirmation; or (c) that We are obliged to sell you the Goods. We will not send you a
                Sale Confirmation unless and until We have purchased the Goods from a third party.
            </p>
            <p>
                <strong>
                    <span className="sub-section-marker">3.5</span> If We cannot accept your Order.
                </strong>{' '}
                If We are unable to supply you with the Goods for any reason, We will inform you of this by email and We
                will not process your Order.{' '}
            </p>

            <h3 id="4-our-goods">4. Our Goods</h3>
            <p>
                <span className="sub-section-marker">4.1</span> In purchasing the Goods, you acknowledge and agree that
                We will be buying the Goods from a third party for immediate on sale to you.
            </p>
            <p>
                <span className="sub-section-marker">4.2</span> Any images of the Goods on Our Marketplace are for
                illustrative purposes only.
            </p>
            <p>
                <span className="sub-section-marker">4.3</span> Although We have made reasonable effort to be as
                accurate as possible, and to ensure that the quality of the Goods and all sizes, weights, capacities,
                dimensions and measurements indicated on Our Marketplace are compliant with the Euronorm(s) relevant to
                the Goods being listed, We do not warrant that this is the case.{' '}
            </p>
            <p>
                <span className="sub-section-marker">4.4</span> The packaging of your Goods may vary from that shown on
                images on Our Marketplace.
            </p>
            <p>
                <span className="sub-section-marker">4.5</span> We reserve the right to amend the specification of the
                Goods if required by any applicable statutory or regulatory requirement.
            </p>

            <h3 id="5-certification">5. Certification</h3>
            <p>
                <span className="sub-section-marker">5.1</span> We do not give any warranty or make any representation
                that:
            </p>
            <ol type="a">
                <li>
                    the Goods conform to any certification which is, or should be, provided with them (for example
                    certification by a steel mill pursuant to EN 10204) (“
                    <strong>Certification</strong>
                    ”);
                </li>
                <li>the authenticity of any Certification given in relation to the Goods;</li>
                <li>
                    the authenticity of any “certified true copies” of any such Certification made and given by previous
                    stockholders of the Goods;
                </li>
                <li>
                    the completeness of the Certification trail between the steel mill manufacturing the Goods and your
                    ownership;
                </li>
                <li>the accuracy of any mill markings on the Goods; and/or</li>
                <li>
                    whether any re-markings on the Goods have been made by stockholders or others accurately and with
                    due authority.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">5.2</span> We may (a) provide you with a certified true copy of any
                Certification or (b) provide you with any original Certification. In each case it will be provided with
                the seller’s name redacted and any other redaction necessary to preserve anonymity. We will not provide
                any other Certification for the period of Our ownership of the Goods or otherwise.{' '}
            </p>

            <h3 id="6-return-and-refund">6. Return and refund</h3>
            <p>
                <span className="sub-section-marker">6.1</span> If the Goods are faulty or mis-described, We will refund
                the price of the Goods and will arrange their collection. Please contact Us at{' '}
                <CustomLink path={`mailto:${t('constants.emailSupport')}`} isInlineLink isAnchorLink>
                    {t('constants.emailSupport')}
                </CustomLink>
                , in order to arrange a suitable time for collection. Prior to collection We will inspect the Goods and
                if We disagree with your assessment of the Goods then We will refer the matter to Dispute Resolution.
                Pending the outcome of the Dispute Resolution We will not collect the Goods and will not refund the
                price of the Goods. Pending the outcome of the Dispute Resolution you must retain the Goods and store
                them in conditions such as will maintain them in the condition in which they were delivered to you.{' '}
            </p>

            <h3 id="7-delivery-transfer-of-risk-and-title">7. Delivery, transfer of risk and title</h3>
            <p>
                <span className="sub-section-marker">7.1</span> Within five days of the date of the Sale Confirmation We
                will notify you of the date that the Goods will be delivered to the location stated in your Order.
                Occasionally Our delivery to you of the Goods by Us or an agent acting on Our behalf may be affected by
                a Force Majeure Event. See clause 15 (Force Majeure) for Our responsibilities when this happens.
            </p>
            <p>
                <span className="sub-section-marker">7.2</span> Delivery is complete once the Goods have been placed at
                your disposal on the arriving transport at the address for delivery set out in your Order and the Goods
                will be at your risk from that time. You are responsible for unloading.
            </p>
            <p>
                <span className="sub-section-marker">7.3</span> Title to the Goods shall not pass to you until the
                earlier of:
            </p>
            <ol>
                <li>
                    when We receive payment in full (in cash or cleared funds) for the Goods and any other goods that We
                    have supplied to you in respect of which payment has become due, in which case title to the Goods
                    shall pass at the time of payment of all such sums; and
                </li>
                <li>
                    when you resell the Goods, in which case title to the Goods shall pass to you at the time specified
                    in clause 7.5.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">7.4</span> Until title to the Goods has passed to you:
            </p>
            <ol type="a">
                <li>
                    you shall store the Goods separately from all other goods held by you so that they remain readily
                    identifiable as Our property;
                </li>
                <li>
                    you shall not remove, deface or obscure any identifying mark or packaging on or relating to the
                    Goods;
                </li>
                <li>
                    you shall maintain the Goods in satisfactory condition and keep them insured against all risks for
                    their full price from the date of delivery;
                </li>
                <li>
                    you shall notify Us immediately if you become subject to any of the following:
                    <ol>
                        <li>
                            you take any step or action in connection with your entering administration, provisional
                            liquidation or any composition or arrangement with your creditors (other than in relation to
                            a solvent restructuring), obtaining a moratorium, being wound up (whether voluntarily or by
                            order of the court, unless for the purpose of a solvent restructuring), having a receiver
                            appointed to any of your assets or ceasing to carry on business or, if the step or action is
                            taken in another jurisdiction, in connection with any analogous procedure in the relevant
                            jurisdiction;
                        </li>
                        <li>
                            you suspend, threaten to suspend, cease or threaten to cease to carry on all or a
                            substantial part of your business; or
                        </li>
                        <li>
                            your financial position deteriorates so far as to reasonably justify the opinion that Our
                            ability to give effect to the terms of the Contract is in jeopardy; and
                        </li>
                    </ol>
                </li>
                <li>
                    you shall give Us such information as We may reasonably require from time to time relating to:
                    <ol>
                        <li>the Goods; and</li>
                        <li>your ongoing financial position.</li>
                    </ol>
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">7.5</span> You may resell or use the Goods in the ordinary course
                of your business (but not otherwise) before We receive payment for the Goods. However, if you resell the
                Goods before that time:
            </p>
            <ol>
                <li>you do so as principal and not as Our agent; and</li>
                <li>
                    title to the Goods shall pass from Us to you immediately before the time at which resale by you
                    occurs.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">7.6</span> If We fail to deliver the Goods to you once We have
                entered into a Contract with you to do so, Our liability is limited to the cost of obtaining replacement
                goods of a similar description and quality in the cheapest market available, less the price of the
                Goods. However, We will not be liable to the extent that any failure to deliver was caused by a Force
                Majeure Event, or because you failed to provide adequate delivery instructions or any other instructions
                that are relevant to the supply of goods.
            </p>
            <p>
                <span className="sub-section-marker">7.7</span> If you fail to take delivery within 3 days after the day
                on which We notified you that the Goods were ready for delivery, We may resell part of, or all the Goods
                and after deducting any reasonable cancellation, storage and selling costs, account to you for any
                excess over the price of the Goods or charge you for any shortfall below the price of the Goods.
            </p>

            <h3 id="8-international-delivery">8. International delivery</h3>
            <p>
                <span className="sub-section-marker">8.1</span> We only deliver to the countries listed on this page{' '}
                <em>Appendix 1: International Destinations</em> (<strong>International Destinations</strong>
                ). However, there are restrictions on some Goods for certain International Destinations, so please
                review the information on that page carefully before ordering Goods. If you order Goods for delivery to
                a country which is not an International Destination, We will not issue a Processing Notice/Sale
                Confirmation and your Order will not be processed.
            </p>
            <p>
                <span className="sub-section-marker">8.2</span> Import duties and taxes are included in the price for
                the Goods stated on Our Marketplace.{' '}
            </p>
            <p>
                <span className="sub-section-marker">8.3</span> You must comply with all applicable laws and regulations
                of the country for which the Goods are destined. We will not be liable or responsible if you break any
                such law.
            </p>

            <h3 id="9-price-of-goods-and-delivery-charges">9. Price of Goods and delivery charges</h3>
            <p>
                <span className="sub-section-marker">9.1</span> The prices of the Goods will be as quoted on Our
                Marketplace at the time you submit your Order. We take all reasonable care to ensure that the prices of
                Goods are correct at the time when the relevant information was entered onto the system.{' '}
            </p>
            <p>
                <span className="sub-section-marker">9.2</span> The price of the Goods excludes VAT. VAT will be added
                to your invoice at the applicable rate.
            </p>
            <p>
                <span className="sub-section-marker">9.3</span> The price of the Goods includes delivery charges.{' '}
            </p>

            <h3 id="10-how-to-pay">10. How to pay</h3>
            <p>
                <span className="sub-section-marker">10.1</span> If you make a pre-payment for the full price of the
                Goods when you place an Order, the terms and conditions governing this pre-payment are set out in the
                Website Terms and Conditions which you can find{' '}
                <CustomLink path={RoutePath.TERMS_WEBSITE} isInlineLink>
                    here
                </CustomLink>
                . We will send you a paid invoice for the Goods when We deliver the Goods to you.{' '}
            </p>
            <p>
                <span className="sub-section-marker">10.2</span> If you do not make a pre-payment for the full price of
                the Goods when you place an Order, We will send you an invoice for the Goods when We deliver the Goods
                to you. You must pay this invoice within 60 days net.
            </p>

            <h3 id="11-our-warranty-for-the-goods">11. Our warranty for the Goods</h3>
            <p>
                <span className="sub-section-marker">11.1</span> We do not warrant that the Goods comply with the laws,
                regulations or standards outside the International Destinations.{' '}
            </p>
            <p>
                <span className="sub-section-marker">11.2</span> We provide a warranty that on delivery, the Goods
                shall:
            </p>
            <ol>
                <li>subject to clause 4, conform in all material respects with their description; and</li>
                <li>be of satisfactory quality (within the meaning of the Sale of Goods Act 1979)</li>
            </ol>
            <p>
                <span className="sub-section-marker">11.3</span> Subject to clause 6.1 and 11.4, if:
            </p>
            <ol type="a">
                <li>
                    you give Us notice in writing within a reasonable time of discovery that some or all of the Goods do
                    not comply with the warranty set out in clause 11.2;
                </li>
                <li>We are given a reasonable opportunity of examining the Goods; and</li>
                <li>We ask you to do so, you return the Goods to Us at Our cost,</li>
            </ol>
            <p>We will refund the price of the defective Goods in full.</p>
            <p>
                <span className="sub-section-marker">11.4</span> We will not be liable for breach of the warranty set
                out in clause 11.2 if:
            </p>
            <div className="contents">
                <ol>
                    <li>you make any further use of the Goods after giving notice to Us under clause 11.3;</li>
                    <li>you alter or repair the Goods without Our written consent;</li>
                    <li>
                        the defect arises as a result of fair wear and tear, wilful damage, negligence, or abnormal
                        storage or working conditions; or
                    </li>
                    <li>
                        the Goods differ from their description or specification as a result of changes made to ensure
                        they comply with applicable statutory or regulatory requirements.
                    </li>
                </ol>
            </div>
            <p>
                <span className="sub-section-marker">11.5</span> We will only be liable to you for the Goods&#39;
                failure to comply with the warranty set out in clause 11.2 to the extent set out in this clause 11.
            </p>
            <p>
                <span className="sub-section-marker">11.6</span> The terms implied by sections 13, 14 and 15 of the Sale
                of Goods Act 1979 are, to the fullest extent permitted by law, excluded from the Contract.
            </p>

            <h3 id="12-our-liability-your-attention-is-particularly-drawn-to-this-clause">
                12. Our liability: your attention is particularly drawn to this clause
            </h3>
            <p>
                <span className="sub-section-marker">12.1</span> References to liability in this clause 12 include every
                kind of liability arising under or in connection with the Contract including but not limited to
                liability in contract, tort (including negligence), misrepresentation, restitution or otherwise.
            </p>
            <p>
                <span className="sub-section-marker">12.2</span> Nothing in these Terms limits or excludes Our liability
                for:
            </p>
            <ol>
                <li>death or personal injury caused by Our negligence;</li>
                <li>fraud or fraudulent misrepresentation;</li>
                <li>
                    breach of the terms implied by section 12 of the Sale of Goods Act 1979 (title and quiet
                    possession); or
                </li>
                <li>any other liability that cannot be limited or excluded by law.</li>
            </ol>
            <p>
                <span className="sub-section-marker">12.3</span> Subject to clause 12.2, We will under no circumstances
                be liable to you for:
            </p>
            <ol>
                <li>any loss of profits, sales, business, or revenue;</li>
                <li>loss or corruption of data, information or software;</li>
                <li>loss of business opportunity;</li>
                <li>loss of anticipated savings;</li>
                <li>loss of goodwill; or</li>
                <li>any indirect or consequential loss.</li>
            </ol>
            <p>
                <span className="sub-section-marker">12.4</span> Subject to clause 12.2, Our total liability to you for
                all losses arising under or in connection with the Contract will in no circumstances exceed the price of
                the Goods.
            </p>
            <p>
                <span className="sub-section-marker">12.5</span> Except as expressly stated in these Terms, We do not
                give any representations, warranties or undertakings in relation to the Goods. Any representation,
                condition or warranty which might be implied or incorporated into these Terms by statute, common law or
                otherwise is excluded to the fullest extent permitted by law. In particular, We will not be responsible
                for ensuring that the Goods are suitable for your purposes.
            </p>

            <h3 id="13-anonymity">13. Anonymity</h3>
            <p>
                <span className="sub-section-marker">13.1</span> Our Marketplace and Our business processes are designed
                so as to maintain your anonymity with respect to other users of Our Marketplace and participants in the
                wider market for steel products. Despite this, We cannot guarantee that your anonymity will be
                maintained and therefore We give no warranty and make no representations in this regard. 13.2 You
                undertake that if, at any time, you are able to identify the person from whom We have bought the Goods
                We have sold to you, you will keep such information confidential.
            </p>

            <h3 id="14-termination">14. Termination</h3>
            <p>
                <span className="sub-section-marker">14.1</span> Without limiting any of Our other rights, We may
                suspend the supply or delivery of the Goods to you, your participation on Our Marketplace, or terminate
                the Contract with immediate effect by giving written notice to you if:
            </p>
            <ol>
                <li>
                    you commit a material breach of any term of the Contract and (if such a breach is remediable) fail
                    to remedy that breach within 5 days of you being notified in writing to do so;
                </li>
                <li>you commit a breach of clause 13.2 or 18</li>
                <li>
                    you fail to pay any amount due under the Contract on the due date for payment within 5 days of you
                    being notified in writing to do so;
                </li>
                <li>
                    you suspend, threaten to suspend, cease or threaten to cease to carry on all or a substantial part
                    of your business; or
                </li>
                <li>
                    your financial position deteriorates to such an extent that in Our reasonable opinion your
                    capability to adequately fulfil your obligations under the Contract has been placed in jeopardy.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">14.2</span> Termination of the Contract shall not affect your or
                Our rights and remedies that have accrued as at termination.
            </p>
            <p>
                <span className="sub-section-marker">14.3</span> Any provision of the Contract that expressly or by
                implication is intended to come into or continue in force on or after termination shall remain in full
                force and effect.
            </p>

            <h3 id="15-force-majeure">15. Force Majeure</h3>
            <p>
                <span className="sub-section-marker">15.1</span> Neither party will be liable or responsible for any
                failure to perform, or delay in performance of its obligations under the Contract that is caused by any
                act or event beyond its or its agent’s or Our supplier’s reasonable control (
                <strong>Force Majeure Event</strong>
                ).{' '}
            </p>
            <p>
                <span className="sub-section-marker">15.2</span> If a Force Majeure Event takes place that affects the
                performance of its obligations under the Contract:
            </p>
            <ol>
                <li>that party will contact the other party as soon as reasonably possible to notify them; and</li>
                <li>
                    that party’s obligations under the Contract will be suspended and the time for performance of its
                    obligations will be extended for the duration of the Force Majeure Event. Where the Force Majeure
                    Event affects Our delivery of Goods to you, We will arrange a new delivery date with you after the
                    Force Majeure Event is over.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">15.3</span> If a Force Majeure Event continues for more than 30
                days either party may cancel the Contract by notifying the other party.{' '}
            </p>

            <h3 id="16-communications-between-us">16. Communications between Us</h3>
            <p>
                <span className="sub-section-marker">16.1</span> When We refer to &quot;in writing&quot; in these Terms,
                this includes email.
            </p>
            <p>
                <span className="sub-section-marker">16.2</span> Any notice or other communication given by one of us to
                the other under or in connection with the Contract must be in writing and be delivered by hand, sent by
                pre-paid first-class post or other next working day delivery service, or email.
                <span className="sub-section-marker">16.3</span> Any notice shall be deemed to have been received:
            </p>
            <ol>
                <li>if delivered by hand, at the time the notice is left at the proper address;</li>
                <li>
                    if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the
                    second Business Day after posting; or
                </li>
                <li>
                    if sent by email, at the time of transmission, or, if this time falls outside Business Hours in the
                    place of receipt, when Business Hours resume.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">16.4</span> For the purposes of this clause, Business Day means: a
                day, other than a Saturday, Sunday or public holiday in England, when banks in London are open for
                business and Business Hours means the period from 9.00 am to 5.00 pm local on any Business Day.
            </p>
            <p>
                <span className="sub-section-marker">16.5</span> In proving the service of any notice, it will be
                sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and
                placed in the post and, in the case of an email, that such email was sent to the specified email address
                of the addressee.
            </p>
            <p>
                <span className="sub-section-marker">16.6</span> The provisions of this clause shall not apply to the
                service of any proceedings or other documents in any legal action.
            </p>

            <h3 id="17-complaints-and-dispute-resolution">17. Complaints and Dispute Resolution</h3>
            <p>
                <span className="sub-section-marker">17.1</span> The complaints and dispute resolution rules applicable
                to the Contract and these Terms is set out in the Website Terms and Conditions which can be found{' '}
                <CustomLink path={RoutePath.TERMS_WEBSITE} isInlineLink>
                    here
                </CustomLink>
                . You and We agree to abide by them, save that nothing in the complaints and dispute resolution rules
                shall prevent either you or Us from applying to the courts at any time for injunctive or other equitable
                relief.
            </p>

            <h3 id="18-confidentiality">18. Confidentiality</h3>
            <p>
                <span className="sub-section-marker">18.1</span> Each party undertakes to the other that it shall not at
                any time disclose to any person any confidential information concerning the business, assets, affairs,
                customers, clients or suppliers of the other party, except as permitted by clause 18.2.
            </p>
            <p>
                <span className="sub-section-marker">18.2</span> Each party may disclose the other’s confidential
                information:
            </p>
            <ol>
                <li>
                    to its employees, officers, representatives, contractors, subcontractors or advisers who need to
                    know such information for the purposes of exercising its rights or carrying out its obligations
                    under or in connection with this Contract. Each party shall ensure that its employees, officers,
                    representatives, contractors, subcontractors or advisers to whom it discloses the other party&#39;s
                    confidential information comply with this clause 18; and
                </li>
                <li>
                    as may be required by law, a court of competent jurisdiction or any governmental or regulatory
                    authority.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">18.3</span> No party shall use any other party&#39;s confidential
                information for any purpose other than to exercise its rights and perform its obligations under or in
                connection with this Contract. However, We are entitled to perpetually and free of charge use your
                confidential information, solely in anonymized and aggregated form that does not identify you, for
                statistical and research purposes, including improving and/or optimizing Our Marketplace or developing
                other products.
            </p>

            <h3 id="19-general">19. General</h3>
            <p>
                <span className="sub-section-marker">19.1</span> <strong>Assignment and transfer.</strong>
            </p>
            <ol>
                <li>
                    We may assign or transfer Our rights and obligations under the Contract to another entity provided
                    that that entity is affiliated to Us at the time of the assignment.
                </li>
                <li>
                    You may only assign or transfer your rights or your obligations under the Contract to another person
                    if We agree in writing.
                </li>
            </ol>
            <p>
                <span className="sub-section-marker">19.2</span> <strong>Variation.</strong> Any variation of the
                Contract only has effect if it is in writing and signed by you and Us (or Our respective authorised
                representatives).
            </p>
            <p>
                <span className="sub-section-marker">19.3</span> <strong>Waiver.</strong> If We do not insist that you
                perform any of your obligations under the Contract, or if We do not exercise Our rights or remedies
                against you, or if We delay in doing so, that will not mean that We have waived Our rights or remedies
                against you or that you do not have to comply with those obligations. If We do waive any rights or
                remedies, We will only do so in writing, and that will not mean that We will automatically waive any
                right or remedy related to any later default by you.
            </p>
            <p>
                <span className="sub-section-marker">19.4</span> <strong>Severance.</strong> Each paragraph of these
                Terms operates separately. If any court or relevant authority decides that any of them is unlawful or
                unenforceable, the remaining paragraphs will remain in full force and effect.
            </p>
            <p>
                <span className="sub-section-marker">19.5</span> <strong>Third party rights.</strong> The Contract is
                between you and Us. No other person has any rights to enforce any of its terms.
            </p>
            <p>
                <span className="sub-section-marker">19.6</span> <strong>Governing law and jurisdiction.</strong> This
                Contract is governed by English law and, subject to clause 17, each party irrevocably agrees to submit
                all disputes arising out of or in connection with this Contract to the exclusive jurisdiction of the
                English courts.
            </p>

            <h3 id="appendix-1-international-destinations">Appendix 1: International Destinations</h3>
            <ol className="numberedOl">
                <li>United Kingdom</li>
            </ol>
        </div>
    );
};
