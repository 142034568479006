import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from '@steelbuy/util';
import { ButtonCallToAction } from '../../button-call-to-action/ButtonCallToAction';

import './MarketingBannerDashboardNavigation.scss';

export type MarketingBannerDashboardNavigationProps = {
    header: string;
    text?: string;
    onClick?: () => void;
    buttonLabel: string;
    imageSrc: string;
    reverse?: boolean;
};

export const MarketingBannerDashboardNavigation = (props: MarketingBannerDashboardNavigationProps) => {
    const { header, text, onClick, buttonLabel, imageSrc, reverse } = props;
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [addPadding, setAddPadding] = useState(false);
    const { width } = useWindowSize();

    useEffect(() => {
        if (contentRef?.current?.scrollHeight) {
            const contentHeight = contentRef.current.scrollHeight;
            if (contentHeight > 220) {
                setAddPadding(true);
            } else {
                setAddPadding(false);
            }
        }
    }, [width]);

    return (
        <article
            className={classNames(
                `marketing-banner-dashboard-navigation ${
                    addPadding && 'marketing-banner-dashboard-navigation__add-padding'
                }`,
                {
                    'marketing-banner-dashboard-navigation--reverse': reverse,
                }
            )}
        >
            <img alt="A smiling person using their laptop to search for steel products" src={imageSrc} />
            <span className="marketing-banner-dashboard-navigation__background" />
            <div
                className={classNames('marketing-banner-dashboard-navigation__body', {
                    'marketing-banner-dashboard-navigation__body--reverse': reverse,
                })}
                ref={contentRef}
            >
                <div className="marketing-banner-dashboard-navigation__body__content">
                    <header className="marketing-banner-dashboard-navigation__body__content__header">{header}</header>
                    <div className="marketing-banner-dashboard-navigation__body__content__text">{text}</div>
                </div>
                <div className="marketing-banner-dashboard-navigation__body__action">
                    <ButtonCallToAction label={buttonLabel} onClick={onClick} type="button" />
                </div>
            </div>
        </article>
    );
};
