import { useEffect, useState } from 'react';
import { Tolerance, formatInputToNumber, formatNumberForInput } from '@steelbuy/ts-shared';
import { MaterialDimensionCreateSelection, MaterialDimensionCreateSpecification } from '@steelbuy/ui-domain';
import { StepProps } from './StepProps';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';
import { useCreateListingContext } from '../CreateListingContext';
import { StepVisitation, useCreateListingStep } from '../CreateListingHooks';

export const DimensionsStep = ({ stepId }: StepProps) => {
    const createListingContext = useCreateListingContext();
    const { materialStepData } = createListingContext;
    const { dimensionsStepData } = createListingContext;

    // dimensions are kept as strings in local state. Parsing to float happens when updating the context.
    const [dimensions, setDimensions] = useState<MaterialDimensionCreateSpecification>({
        thickness: formatNumberForInput(dimensionsStepData.thickness) || undefined,
        width: formatNumberForInput(dimensionsStepData.width) || undefined,
        length: formatNumberForInput(dimensionsStepData.length) || undefined,
    });
    const { formRef, refreshStepStatus, stepVisitation } = useCreateListingStep(stepId);

    // refresh status when material data changes
    useEffect(() => {
        refreshStepStatus();
    }, [materialStepData]);

    const handleDimensionChange = (newDimensions: MaterialDimensionCreateSpecification) => {
        setDimensions(newDimensions);

        createListingContext.updateDimensionsStepData({
            thickness: newDimensions.thickness ? formatInputToNumber(newDimensions.thickness) : undefined,
            width: newDimensions.width ? formatInputToNumber(newDimensions.width) : undefined,
            length: newDimensions.length ? formatInputToNumber(newDimensions.length) : undefined,
        });
    };

    const handleToleranceChange = (newToleranceThickness?: Tolerance) => {
        createListingContext.updateDimensionsStepData({
            tolerance: newToleranceThickness,
        });
    };

    return (
        <div className="dimensions-step">
            <FormLayout>
                <form ref={formRef}>
                    <MaterialDimensionCreateSelection
                        product={materialStepData.product}
                        dimensions={dimensions}
                        setDimensions={handleDimensionChange}
                        prime={materialStepData.prime}
                        toleranceThickness={dimensionsStepData.tolerance}
                        setToleranceThickness={handleToleranceChange}
                        required
                        forceValidation={stepVisitation === StepVisitation.VISITED}
                        materialType={materialStepData.materialType}
                    />
                </form>
            </FormLayout>
        </div>
    );
};
