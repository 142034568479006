import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validatePurchaseOrderNumber } from '@steelbuy/ts-shared';
import { Card, CardContentCheckoutPurchaseOrderNumber, CardLayout } from '@steelbuy/ui-primitive';

type CheckoutPurchaseOrderNumberProps = {
    setBuyerOrderNumber: (buyerOrderNumber: string) => void;
    control: Control;
};

const CheckoutPurchaseOrderNumber = ({ setBuyerOrderNumber, control }: CheckoutPurchaseOrderNumberProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    return (
        <form>
            <Card isClickable={false}>
                <CardLayout>
                    <Controller
                        name="buyerOrderNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: { value: true, message: t('uiDomain:validation.requiredField') },
                            validate: (value) =>
                                validatePurchaseOrderNumber(value) ||
                                t('translation:application.checkout.purchaseOrderErrorMsg'),
                        }}
                        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                            <CardContentCheckoutPurchaseOrderNumber
                                header={t('translation:application.checkout.purchaseOrderNumber')}
                                onChangeTextInput={(val) => {
                                    onChange(val);
                                    setBuyerOrderNumber(val);
                                }}
                                buyerPurchaseOrderNumber={value}
                                placeholderText={t('translation:application.checkout.purchaseOrderPlaceholderText')}
                                error={error?.message}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </CardLayout>
            </Card>
        </form>
    );
};

export default CheckoutPurchaseOrderNumber;
