import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
    ButtonPrimary,
    ButtonSecondary,
    FormActionbar,
    FormItem,
    Icon,
    IconIdentifier,
    InputTextfield,
    LoadingStatus,
} from '@steelbuy/ui-primitive';

import './EmailAddressForm.scss';

interface EmailAddressFormProps {
    onCancel: () => void;
    onSubmit: (email: string) => void;
    defaultEmail: string;
    isLoading?: boolean;
}

export const EmailAddressForm = ({ onCancel, onSubmit, defaultEmail, isLoading }: EmailAddressFormProps) => {
    const { t } = useTranslation('uiDomain');

    const schema = z
        .object({
            email: z
                .string()
                .email(t('emailAddressForm.enterValidEmail'))
                .min(1, t('emailAddressForm.enterValidEmail')),
        })
        .refine(({ email }) => email !== defaultEmail, {
            message: t('emailAddressForm.emailNotUpdated'),
        });

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<z.infer<typeof schema>>({
        defaultValues: {
            email: defaultEmail,
        },
        resolver: zodResolver(schema),
        mode: 'onBlur',
    });

    const onFormSubmit = handleSubmit(async ({ email }) => {
        onSubmit(email);
    });

    return (
        <div className="email-address-form">
            <form onSubmit={onFormSubmit}>
                <FormItem>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, onBlur } }) => (
                            <InputTextfield
                                defaultValue={defaultEmail}
                                label={t('emailAddressForm.emailLabel')}
                                type="text"
                                error={errors.email?.message}
                                onChange={onChange}
                                onBlur={onBlur}
                                endAdornment={<Icon name={IconIdentifier.PERSON} />}
                            />
                        )}
                    />
                </FormItem>
                <FormActionbar>
                    <ButtonSecondary label={t('common.cancel')} onClick={onCancel} />
                    <ButtonPrimary
                        label={t('emailAddressForm.submitLabel')}
                        disabled={!isValid}
                        type="submit"
                        loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                    />
                </FormActionbar>
            </form>
        </div>
    );
};
