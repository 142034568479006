import { FileUploadStatus } from '../lib/input-file/input-file-item/InputFileItem';

export default {
    checkoutDeliveryOption: {
        headerLabel: 'Delivery Option',
        headerDescriptionLabel: 'Should SteelBuy deliver all items to a single address or multiple addresses?',
        singleAddressLabel: 'Single address',
        singleAddressDescriptionLabel: 'Your order will be shipped to 1 address.',
        multipleAddressLabel: 'Multiple addresses',
        multipleAddressDescriptionLabel: 'Your order will be shipped to multiple addresses.',
    },
    checkout: {
        delivery: {
            title: 'Delivery',
            haulierRequiredMessage: 'Do we need to book a delivery slot for this item?',
            checkboxYes: 'Yes',
            checkboxNo: 'No',
            materialLocationLabel: 'Material location',
        },
        deliveryText: 'Expected delivery time',
        payment: {
            optionCreditLabel: 'Credit',
            optionCreditExplanation: 'Your order will be shipped directly.',
            optionUpfrontLabel: 'Upfront payment',
            optionUpfrontExplanation: 'Your order will be shipped after we receive your payment.',
        },
        shippingCostsLabel: 'Delivery',
        totalExclVat: 'Total (excl. VAT)',
        itemsTotalExclVat: 'Items Total (excl. VAT)',
        totalInclVat: 'Total (incl. VAT)',
        vatLabel: '+ {{value}}% VAT',
        weight: 'Weight',
        theoreticalWeight: 'Theoretical weight',
    },
    fileInput: {
        showMoreLabel: 'Show More',
        showLessLabel: 'Show Less',
        fileInputLabel: 'Please select files',
        filesUploaded: '{{uploadedCount}} of {{totalFiles}} uploaded',
        filesizeKB: '{{size}}KB',
        filesizeMB: '{{size}}MB',
        sizeAndStatus: '{{size}} - {{status}}',
        fileUploadStatus: {
            [FileUploadStatus.Uploading]: 'Uploading',
            [FileUploadStatus.Error]: 'Error',
            [FileUploadStatus.Success]: 'Uploaded',
        },
        browse: 'Browse',
    },
};
