import { useMutation } from '@tanstack/react-query';
import axios from '../axios';
import { getCsrfToken } from '../query/account/getCrsfToken';

export type UpdateEmailAddressPayload = {
    email: string;
};

const updateEmailAddress = async (payload: UpdateEmailAddressPayload) => {
    const token = await getCsrfToken();
    const { data } = await axios.put(
        'api/universal/users/email-change',
        {
            email: payload.email,
        },
        {
            headers: {
                csrfEmailToken: token.csrfEmailToken,
            },
        }
    );
    return data;
};

export const useUpdateEmailAddress = () =>
    useMutation({
        mutationFn: (payload: UpdateEmailAddressPayload) => updateEmailAddress(payload),
    });
