import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserSelfDetails } from '@steelbuy/data-access';
import {
    FetchStatus,
    useAddressCollectionData,
    useListingBuyerEntityData,
    useOrderBuyerCreateEntityData,
} from '@steelbuy/data-provider';
import { useModal } from '@steelbuy/modal-dialog';
import {
    formatCurrency,
    AddressModel,
    ListingOrderModel,
    OfferListingStatus,
    OfferView,
    TradeUnitCalculation,
    getMinimumTargetPrice,
    getPricingUnit,
    getTradeUnitCalculation,
    formatNumber,
} from '@steelbuy/ts-shared';
import { ListingBuyerTeaser, useNumberOfItemsLabel } from '@steelbuy/ui-domain';
import {
    AddressIdentifier,
    ButtonCallToAction,
    ButtonSecondary,
    Card,
    CardContentCheckoutDelivery,
    CardContentCheckoutPayment,
    CardContentProductViewPrice,
    CardLayout,
    FormActionbar,
    LoadingSpinner,
    LoadingStatus,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { CheckoutCancelModal } from './CheckoutCancelModal';
import CheckoutCheckboxes from './CheckoutCheckboxes';
import { useCheckoutContext } from './CheckoutContext';
import { CheckoutErrorNotification } from './CheckoutErrorNotification';
import CheckoutPurchaseOrderNumber from './CheckoutPurchaseOrderNumber';
import { CHOOSE_ADDRESS_RELATIVE_PATH } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { AddressDetails } from '../address-details/AddressDetails';
import { Negotiations } from '../offers/Negotiations';
import { PageHeader } from '../page-header/PageHeader';

import './CheckoutForm.scss';

interface CheckoutFormProps {
    listingDetailPath: string;
}

export const CheckoutForm = ({ listingDetailPath }: CheckoutFormProps) => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);

    const navigate = useNavigate();
    const checkoutContext = useCheckoutContext();
    const {
        formState: { isValid },
        control,
        trigger,
    } = useForm({ mode: 'all' });

    const { invoiceAddressCollectionData, warehouseAddressCollectionData } = useAddressCollectionData();
    const listingEntityData = useListingBuyerEntityData();
    const orderCreateData = useOrderBuyerCreateEntityData();
    const { data: userData } = useUserSelfDetails();

    const listingModel = listingEntityData.query().get();
    const numberOfItemsLabel = useNumberOfItemsLabel(listingModel?.material.product);
    const cancelModal = useModal();

    const [showPriceUpdateError, setShowPriceUpdateError] = useState(false);

    const invoiceAddresses = invoiceAddressCollectionData.query();
    const warehouseAddresses = warehouseAddressCollectionData.query();

    const defaultInvoiceAddressId = invoiceAddresses[0]?.id ?? '';
    const defaultWarehouseAddressId =
        (warehouseAddresses.find((address: AddressModel) => address.primary) ?? warehouseAddresses[0])?.id ?? '';

    listingEntityData.useFetchStatusEffect([FetchStatus.IDLE], () => {
        listingEntityData.fetch();
    });

    const checkoutPrice = useRef<number | null>(null); // only gets set when user clicks checkout
    const isLoading = listingEntityData.queryFetchStatus() === FetchStatus.PENDING;

    const createPurchaseOrder = () => {
        if (checkoutContext.deliveryAddressId && listingModel) {
            const acceptedNegotiation = listingModel.negotiations?.find(
                (negotiation) => negotiation.status === 'ACCEPTED'
            );
            orderCreateData.create({
                listingId: listingModel.id,
                negotiationId: acceptedNegotiation?.id,
                invoiceAddressId: defaultInvoiceAddressId,
                deliveryAddressId: checkoutContext.deliveryAddressId,
                additionalDeliveryInformation: checkoutContext.additionalDeliveryInformation,
                buyerOrderNumber: checkoutContext.buyerOrderNumber?.trim(),
                haulierRequired: checkoutContext.haulierRequired,
            } as ListingOrderModel);
        }
    };

    listingEntityData.useFetchStatusEffect([FetchStatus.SUCCESS], () => {
        if (checkoutPrice.current && checkoutContext.deliveryAddressId && listingModel) {
            if (checkoutPrice.current !== listingModel.pricing.priceIncludingDelivery.value) {
                checkoutContext.setAgreedAuthorised(false);
                checkoutContext.setAgreedTermsAndConditions(false);
                setShowPriceUpdateError(true);
                return;
            }
            createPurchaseOrder();
        }
        checkoutPrice.current = null;
    });

    useEffect(() => {
        if (!checkoutContext.deliveryAddressId) {
            checkoutContext.setDeliveryAddressId(defaultWarehouseAddressId);
        }
    }, [defaultWarehouseAddressId]);

    useEffect(() => {
        if (!isValid && (checkoutContext.agreedTermsAndConditions || checkoutContext.agreedAuthorised)) {
            trigger();
        }
    }, [checkoutContext.agreedTermsAndConditions, checkoutContext.agreedAuthorised]);

    if (!listingModel) {
        return <LoadingSpinner />;
    }

    const buyIsDisabled = () => {
        if (!checkoutContext.agreedTermsAndConditions || !isValid) {
            return true;
        }
        return !checkoutContext.agreedAuthorised;
    };

    const handleCheckout = () => {
        if (!checkoutContext.deliveryAddressId) {
            return;
        }

        if (listingModel.offerPricing?.priceIncludingDelivery) {
            createPurchaseOrder();
            return;
        }

        checkoutPrice.current = listingModel.pricing.priceIncludingDelivery.value;
        listingEntityData.resolveFetchStatus(); // fetch listing to check if price has changed
    };

    const acceptedNegotiation = listingModel.negotiations?.find(
        (negotiation) => negotiation.status === OfferListingStatus.ACCEPTED
    );

    const weightOrItems =
        getTradeUnitCalculation(listingModel.material) === TradeUnitCalculation.BY_WEIGHT
            ? listingModel.weight
            : listingModel.numberOfItems || 0;

    let errorMessage = '';
    if (!checkoutContext.agreedTermsAndConditions || !checkoutContext.agreedAuthorised || !isValid) {
        errorMessage = t('translation:application.checkout.incompleteInformationNotification');
    }

    return (
        <>
            <PageHeader
                pageTitle={t('translation:application.checkout.header.title')}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(listingDetailPath)}
                showCartIcon={false}
            />

            <div className="checkout-view">
                <TableLayout>
                    <Card isClickable={false}>
                        <ListingBuyerTeaser
                            listing={listingModel}
                            offerValue={listingModel.offerPricing?.priceIncludingDelivery.value}
                            {...listingModel}
                        />
                    </Card>
                    {showPriceUpdateError && (
                        <Notification
                            level={NotificationLevel.WARNING}
                            message={t('translation:application.checkout.priceUpdatedError')}
                            className="price-updated-error"
                            disableScroll={false}
                        />
                    )}
                </TableLayout>

                <DetailsLayout>
                    {acceptedNegotiation && (
                        <Negotiations
                            negotiations={[acceptedNegotiation]}
                            view={OfferView.BUYER}
                            onStatusChange={() => {}}
                            pricingUnit={getPricingUnit(listingModel.material)}
                            weightOrItems={weightOrItems}
                            minimumPrice={getMinimumTargetPrice(
                                listingModel.material,
                                listingModel.weight,
                                listingModel.numberOfItems
                            )}
                        />
                    )}
                    <Card isClickable={false}>
                        <CardLayout>
                            <AddressDetails
                                addressId={defaultInvoiceAddressId}
                                type={AddressIdentifier.BILLING_ADDRESS}
                                additionalInfoLabel={t(
                                    'translation:application.addressDetails.additionalInformationLabel'
                                )}
                            />
                        </CardLayout>
                    </Card>
                    {/* buyer purchase order number input field */}

                    <CheckoutPurchaseOrderNumber
                        setBuyerOrderNumber={checkoutContext.setBuyerOrderNumber}
                        control={control}
                    />

                    {/* payment  */}
                    <Card isClickable={false}>
                        <CardLayout>
                            <CardContentCheckoutPayment
                                header={t('translation:application.checkout.payment.sectionHeading')}
                                notificationMessage={t(
                                    'translation:application.checkout.payment.creditInfoNotification'
                                )}
                            />
                        </CardLayout>
                    </Card>
                    <Card isClickable={false}>
                        <CardLayout>
                            <AddressDetails
                                addressId={checkoutContext.deliveryAddressId}
                                type={AddressIdentifier.DELIVERY_ADDRESS}
                                onButtonClick={() => navigate(CHOOSE_ADDRESS_RELATIVE_PATH)}
                                additionalText={checkoutContext.additionalDeliveryInformation ?? ''}
                                onChangeAdditionalText={(value) =>
                                    checkoutContext.setAdditionalDeliveryInformation(value)
                                }
                                additionalInfoLabel={t(
                                    'translation:application.addressDetails.deliveryInformationLabel'
                                )}
                                additionalInfoPlaceholder={t(
                                    'translation:application.pickupAddressStep.additionalInfoPlaceholder'
                                )}
                                isMandatory
                            />
                        </CardLayout>
                    </Card>

                    {/* delivery info */}
                    <CardContentCheckoutDelivery
                        deliveryPromise={listingModel.deliveryPromise}
                        onHaulierRequiredChanged={(value) => {
                            checkoutContext.setHaulierRequired(value);
                        }}
                        haulierRequired={checkoutContext.haulierRequired}
                        countryCode={listingModel.country}
                    />

                    {/* weight & price */}
                    <Card isClickable={false}>
                        <CardLayout>
                            <CardContentProductViewPrice
                                header={t('translation:application.checkout.totalPriceSectionHeading')}
                                pricePerUnit={formatCurrency(
                                    listingModel.pricing.priceIncludingDelivery.value,
                                    listingModel.pricing.currencyCode
                                )}
                                offerPricePerUnit={
                                    listingModel?.offerPricing?.priceIncludingDelivery
                                        ? formatCurrency(
                                              listingModel?.offerPricing?.priceIncludingDelivery.value,
                                              listingModel?.offerPricing?.currencyCode
                                          )
                                        : undefined
                                }
                                pricePerUnitLabel={t('translation:application.checkout.pricePerUnitInclDelivery', {
                                    tradeUnit: t(
                                        `domainModel:material.pricingUnit.value.${getPricingUnit(
                                            listingModel.material
                                        )}.label`
                                    ),
                                })}
                                weight={t(`domainModel:material.tradeUnit.value.${listingModel.tradeUnit}.quantity`, {
                                    count: listingModel.weight,
                                    formatted: formatNumber(listingModel.weight),
                                })}
                                totalExVat={formatCurrency(
                                    listingModel.pricing.totalExcludingVat.value,
                                    listingModel.pricing.currencyCode
                                )}
                                offerTotalExVat={
                                    listingModel?.offerPricing?.totalExcludingVat
                                        ? formatCurrency(
                                              listingModel?.offerPricing?.totalExcludingVat.value,
                                              listingModel?.offerPricing?.currencyCode
                                          )
                                        : undefined
                                }
                                vatPercent={(listingModel.pricing.taxPercent / 100).toString()}
                                vat={
                                    listingModel?.offerPricing?.vat
                                        ? formatCurrency(
                                              listingModel?.offerPricing.vat.value,
                                              listingModel?.offerPricing.currencyCode
                                          )
                                        : formatCurrency(
                                              listingModel.pricing.vat.value,
                                              listingModel.pricing.currencyCode
                                          )
                                }
                                totalPrice={formatCurrency(
                                    listingModel.pricing.totalIncludingVat.value,
                                    listingModel.pricing.currencyCode
                                )}
                                offerTotalPrice={
                                    listingModel.offerPricing?.totalIncludingVat
                                        ? formatCurrency(
                                              listingModel.offerPricing.totalIncludingVat.value,
                                              listingModel.offerPricing.currencyCode
                                          )
                                        : undefined
                                }
                                numberOfItems={
                                    numberOfItemsLabel && listingModel.numberOfItems
                                        ? {
                                              label: numberOfItemsLabel,
                                              amount: listingModel.numberOfItems,
                                          }
                                        : undefined
                                }
                                isAccepted={!!acceptedNegotiation}
                            />
                        </CardLayout>
                    </Card>

                    {/* Check boxes */}
                    {userData ? <CheckoutCheckboxes /> : null}

                    {errorMessage && <CheckoutErrorNotification errorMessage={errorMessage} />}

                    <FormActionbar>
                        <ButtonSecondary label={t('uiDomain:common.cancel')} onClick={cancelModal.show} />
                        <ButtonCallToAction
                            label={t('translation:application.checkout.submitCheckoutButtonLabel')}
                            disabled={buyIsDisabled() || isLoading}
                            onClick={() => handleCheckout()}
                            loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                    </FormActionbar>

                    <CheckoutCancelModal
                        modalAccessor={cancelModal}
                        action={() => {
                            checkoutContext.reset();
                            navigate(listingDetailPath);
                        }}
                    />
                </DetailsLayout>
            </div>
        </>
    );
};
