import {
    ListingStatus,
    OrderStatus,
    PricingUnit,
    Product,
    OfferListingStatus,
    CustomOfferListingStatus,
    RFQStatus,
} from '@steelbuy/ts-shared';
import { FileUploadStatus } from '@steelbuy/ui-primitive';
import { SortCriteriaOption } from '../lib/search-results/SearchResults.enums';

export default {
    addressForm: {
        primaryTagLabel: 'Standardadresse',
        companyName: 'Name der Firma',
        addressLine1: 'Adresszeile 1',
        addressLine2: 'Adresszeile 2 (optional)',
        town: 'Stadt',
        postcode: 'Postleitzahl',
        primaryAddressNotDeletable: 'Es ist nicht möglich, Ihre Hauptadresse zu löschen. ',
        country: 'Land',
        phoneNumber: 'Telefonnummer (optional)',
        saveAddressButtonLabel: 'Adresse speichern',
        setAsPrimary: 'Als primäre Adresse festlegen',
        updateAddressButtonLabel: 'Änderungen speichern',
    },
    autoRenew: {
        autoRenewHeader: 'Automatische Verlängerung des Angebotes',
        autoRenewHelper:
            'Das Angebot wird automatisch verlängert, wenn es zum Ablaufdatum nicht verkauft wurde. Sollten hierzu Änderungen erforderlich sein, erhalten Sie vorab eine Benachrichtigung. Es erhöht die Sichtbarkeit des Eintrags und erweitert seine Reichweite für potenzielle Käufer.',
        autoRenewLabel: 'Automatische Verlängerung des Angebotes',
    },
    cardContentCheckoutAddresses: {
        listHeading: 'Abholadresse',
        notificationMessage: 'Bitte wählen Sie die Adresse, von der wir das Material abholen können.',
    },
    checkoutDeliveryAddress: {
        listHeading: 'Lieferadresse',
        notificationMessage: 'Bitte wählen Sie die Lieferadresse aus, an die wir das Material liefern sollen.',
    },
    common: {
        edit: 'Bearbeiten',
        delete: 'Löschen',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
        back: 'Zurück',
        saveChanges: 'Änderungen speichern',
        remove: 'Entfernen',
        retry: 'Wiederholen',
    },
    commonList: {
        loadMore: 'Mehr laden',
        noListCaption: 'Keine Ergebnisse gefunden',
        buyNow: 'Jetzt kaufen',
        addToCart: 'Hinzufügen',
        addedToCart: 'Hinzugefügt',
        new: 'Neu',
    },
    confirmSelectionModal: {
        confirmLabel: 'Weitermachen',
        header: 'Änderungen wirken sich auf Ihre Auswahl auf dem nächsten Bildschirm aus. Fortfahren?',
    },
    createListingTeaser: {
        attributeAge: 'Alter',
        attributeSku: 'Material-ID',
        expirationTime: 'Ablauf: ',
    },
    customPrompt: {
        discardButtonLabel: 'Änderungen verwerfen',
    },
    deleteDraftModal: {
        confirmButtonLabel: 'Entwurf löschen',
        textContent: 'Möchten Sie diesen Entwurf wirklich löschen?',
    },
    emailAddressForm: {
        emailLabel: 'E-Mail-Adresse',
        submitLabel: 'E-Mail-Adresse aktualisieren',
        enterValidEmail: 'Geben Sie eine gültige E-Mail-Adresse ein',
        emailNotUpdated: 'E-Mail-Adresse wurde nicht aktualisiert',
    },
    fileInput: {
        showMoreLabel: 'Mehr anzeigen',
        showLessLabel: 'Weniger anzeigen',
        viewButton: 'Sicht',
        fileInputLabel: 'Bitte wählen Sie die Datei(en) zum Hochladen aus',
        filesUploaded: '{{uploadedCount}} von {{totalFiles}} hochgeladen',
        filesizeKB: '{{size}}KB',
        filesizeMB: '{{size}}MB',
        sizeAndStatus: '{{size}} - {{status}}',
        fileUploadStatus: {
            [FileUploadStatus.Uploading]: 'Hochladen',
            [FileUploadStatus.Error]: 'Fehler',
            [FileUploadStatus.Success]: 'Hochgeladen',
        },
    },
    login: {
        headerTitle: 'Login',
        errors: {
            usernameRequired: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
            passwordRequired: 'Passwort ist erforderlich',
            passwordFormatError:
                'Das Passwort darf nur Buchstaben, Zahlen und Sonderzeichen ohne Leerzeichen enthalten',
            passwordIncorrect: 'Ihre E-Mail-Adresse oder Ihr Passwort sind falsch.',
            serverError: 'Interner Serverfehler',
            temporaryPasswordExpired: 'Das temporäre Passwort ist abgelaufen. ',
            timeoutError: 'Ihre Sitzung ist abgelaufen. ',
            unauthorized: 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen',
            rateLimit: 'Sie haben das Limit für erfolglose Anmeldeversuche erreicht.',
            validationError: 'Fehler: Es ist ein Fehler aufgetreten.',
            invalidEmail: 'Ungültige E-Mail',
        },
        forgotPassword: 'Passwort vergessen?',
        usernameLabel: 'E-Mail',
        passwordLabel: 'Passwort',
        usernamePlaceholder: 'Geben Sie die E-Mail-Adresse ein',
        passwordPlaceholder: 'Passwort eingeben',
        submitLabel: 'Login',
        challengeRespond: {
            forcePasswordChange: {
                header: 'Neues Passwort erstellen',
                subheading: {
                    welcome: 'Willkommen, {{username}}. Bitte vergeben Sie ein neues Passwort für Ihr Konto.',
                },
                successNotification: 'Ihr Passwort wurde geändert. ',
            },
            softwareTokenMfa: {
                header: 'Zwei-Faktor-Authentifizierung',
                subheading:
                    'Für zusätzliche Sicherheit wurde in Ihrem Konto die Zwei-Faktor-Authentifizierung (2FA) aktiviert.',
                info: 'Geben Sie den von der App generierten Authentifizierungscode ein.',
                sixDigitCodeLabel: '6-stelliger Code',
                submitLabel: 'Einreichen',
            },
            mfaSetup: {
                header: 'Zwei-Faktor-Authentifizierung',
                subheading:
                    'Für zusätzliche Sicherheit wurde in Ihrem Konto die Zwei-Faktor-Authentifizierung (2FA) aktiviert.',
                instructions: {
                    downloadAndInstall:
                        'Laden Sie eine App (z. B. Google Authenticator) herunter und installieren Sie sie auf Ihrem Mobilgerät.',
                    scanQRCode: 'Scannen Sie den QR-Code.',
                    verifyAuthCode:
                        'Geben Sie den von der App generierten Authentifizierungscode ein und überprüfen Sie ihn.',
                },
            },
        },
        tempPasswordEmailSent: 'Die Willkommens-E-Mail wurde erneut gesendet {{username}}',
        newAccount: {
            label: 'Benötigen Sie ein Konto?',
            link: 'Fordern Sie ein Konto an',
        },
        resetPassword: {
            header: 'Passwort zurücksetzen',
            tokenExpired: 'Der Link ist abgelaufen. ',
            resendEmail: 'E-Mail erneut senden',
            successNotification: 'Ihr Passwort wurde geändert. ',
            expiredPassword: {
                expiredTokenError: {
                    header: 'Der Link zum Zurücksetzen des Passworts ist abgelaufen',
                    body: 'Der E-Mail-Bestätigungslink läuft nach 24 Stunden ab und kann nur einmal verwendet werden',
                },
                unknownTokenError: {
                    header: 'Ungültiger Passwort-Link',
                    body: 'Der Passwort-Link ist ungültig. Bitte senden Sie den Link erneut und versuchen Sie es erneut',
                },
                submitLabel: 'Link zum Zurücksetzen des Passworts erneut senden',
            },
        },
        backToLogin: 'Zurück zum Anmelden',
        passwordChangedNotification: 'Ihr Passwort wurde geändert.\n',
    },
    listingBuyerDetailsPrice: {
        cardTitle: 'Gewicht und Preis',
        pricePerUnit: 'Preis pro {{pricingUnit}} (inkl. Lieferung)',
        totalExclVat: 'Gesamt (exkl. MwSt.)',
    },
    cart: {
        subtotal: 'Zwischensummenwert',
        exclVat: '(exkl. MwSt.)',
        item_one: '({{count}} Artikel)',
        item_other: '({{count}} Artikel)',
        subtotalWeight: 'Zwischensummengewicht',
    },
    checkoutCart: {
        item_one: '{{count}} Artikel',
        item_other: '{{count}} Artikel',
    },
    cartModal: {
        pricePerUnit: 'pro {{pricingUnit}}, inkl. Lieferung',
    },
    listingDraftTeaser: {
        lastEdited: 'Zuletzt bearbeitet',
        draft: 'Entwurf',
    },
    listingSellerStatus: {
        [ListingStatus.DRAFT]: 'Entwurf',
        [ListingStatus.REVIEW_REQUIRED]: 'In Prüfung',
        [ListingStatus.REVIEW_REJECTED]: 'Abgelehnt',
        [ListingStatus.PUBLISHED]: 'Online',
        [ListingStatus.PURCHASED]: 'Verkauft',
        [ListingStatus.WITHDRAWN]: 'Zurückgezogen',
        [ListingStatus.EXPIRED]: 'Abgelaufen',
    },
    listingTeaser: {
        expiryDate: 'Läuft ab: {{expiryDate}}',
    },
    listingSellerTeaser: {
        today: 'Heute',
    },
    listingDetailsStatus: {
        header: {
            [ListingStatus.DRAFT]: 'Zuletzt gespeichert unter:',
            [ListingStatus.REVIEW_REQUIRED]: 'Zur Prüfung eingereicht am:',
            [ListingStatus.REVIEW_REJECTED]: 'Abgelehnt am:',
            [ListingStatus.PUBLISHED]: 'Online seit:',
            [ListingStatus.PURCHASED]: 'Zum Kauf aufgefordert bei:',
            [ListingStatus.WITHDRAWN]: 'Zurückgezogen am:',
            [ListingStatus.EXPIRED]: 'Abgelaufen am:',
        },
        expiredInfo:
            'Sie können dieses Angebot verlängern, wenn Sie ein neues Ablaufdatum festlegen, und bei Bedarf auch Ihren Preis anpassen. Anschließend können Sie den erneuerten Eintrag direkt auf dem Marktplatz veröffentlichen.',
    },
    listingDetailsExpiration: {
        title: 'Ablaufdatum',
        text: 'Das Angebot läuft ab am',
        autoRenewLabel: 'Automatische Verlängerung des Angebotes',
        autoRenewEnabled: 'Aktiv',
        autoRenewDisabled: 'Deaktiviert',
        autoRenewToggleHelper:
            'Es erhöht die Sichtbarkeit des Eintrags und erweitert seine Reichweite für potenzielle Käufer.',
        autoRewewExpiryLabel: 'Neues Ablaufdatum nach automatischer Verlängerung',
    },
    expiryTimeSelection: {
        formItemHeader: 'Ablaufdatum des Eintrags',
        label: 'Das Ablaufdatum wir aktiviert, wenn Sie Ihr Angebot zur Prüfung einreichen. Für den entsprechenden Zeitraum stimmen Sie zu, Ihr Angebot exklusiv auf SteelBuy zu verkaufen.',
        selectDatePlaceholder: 'Wählen Sie das Ablaufdatum',
        selectTimePlaceholder: 'Zeit',
    },
    forgotPassword: {
        title: 'Passwort vergessen?',
        subheading:
            'Geben Sie unten Ihren Benutzernamen ein und wir senden Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts',
        submitLabel: 'Senden Sie eine E-Mail zum Zurücksetzen des Passworts',
        enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    },
    passwordLinkSent: {
        title: 'Link zum Zurücksetzen des Passworts gesendet',
        subheading: 'Wenn Sie auf ein Problem stoßen, wenden Sie sich bitte an den Kundendienst.',
        notification:
            'Wenn ein Konto vorhanden ist für {{username}} Wir senden Ihnen Anweisungen zum Zurücksetzen Ihres Passworts.',
        backToSignIn: 'Zurück zur Anmeldung',
    },
    listingDetailsViews: {
        header: 'Leistung',
        numberOfViews: 'Anzahl der Ansichten',
    },
    listingDetailsMaterial: {
        detailsTitle: 'Einzelheiten',
        skuLabel: 'Ihre Material-ID',
    },
    listingTeaserContainer: {
        buyerPurchaseOrderNumber: 'Ihre Bestellnummer:',
        steelBuyPurchaseOrderNumber: 'SteelBuy-Bestellnummer:',
        sellerMaterialId: 'Ihre Material-ID:',
        negotiationReference: 'Verhandlungsreferenznummer: ',
        materialLocation: 'Materialstandort:',
        weight: 'Gewicht:',
        origin: 'Herkunft:',
        age: 'Alter:',
        prime: 'Erstklassig',
        nonPrime: 'Nicht erstklassig',
    },
    listingTeaserOfferBadge: {
        status: {
            [OfferListingStatus.ACCEPTED]: 'Akzeptiert',
            [OfferListingStatus.AWAITING_SELLER]: 'Warten auf die Antwort des Verkäufers',
            [OfferListingStatus.AWAITING_BUYER]: 'Warten auf die Antwort des Käufers',
            [OfferListingStatus.REJECTED]: 'Abgelehnt',
            [OfferListingStatus.EXPIRED]: 'Abgelaufen',
            [OfferListingStatus.WITHDRAWN]: 'Zurückgezogen',
            [OfferListingStatus.CANCELLED]: 'Abgesagt',
            [CustomOfferListingStatus.NO_SUCCESSFUL_OFFERS]: 'Keine erfolgreichen Angebote',
            [OfferListingStatus.PURCHASED]: 'Verkauft',
        },
        awaitingYourResponse: 'Warten auf Ihre Antwort',
        outbid: 'Überbieten',
        numberOffersHasLabel: 'Dieses Angebot hat {{numberOffers}} Angebote',
        numberOffersHadLabel: 'Dieses Angebot hatte {{numberOffers}} Angebote',
        numberOffersHasOneLabel: 'Dieses Angebot hat {{numberOffers}} Angebot',
        offerInProgress: 'Angebot in Bearbeitung',
    },
    rfqTeaserStatusBadge: {
        status: {
            [RFQStatus.REJECTED]: 'Abgelehnt',
            [RFQStatus.LIVE]: 'Live',
            [RFQStatus.WITHDRAWN]: 'Zurückgezogen',
            [RFQStatus.EXPIRED]: 'Abgelaufen',
            [RFQStatus.UNDER_REVIEW]: 'Wird überprüft',
        },
    },
    rfqBuyerTeaser: {
        expiryDate: 'Läuft ab: {{-expiryDate}}',
    },
    rfqForm: {
        definition: {
            description: 'Sie können eine oder mehrere Definitionen gleichzeitig auswählen.',
        },
        plateType: {
            description: 'Sie können einen oder mehrere Typen gleichzeitig auswählen.',
        },
    },
    rfqDraftTeaser: {
        lastEdited: 'Zuletzt bearbeitet',
        infoFieldsMissing_one: 'Informationen in {{ count }} Feld fehlt',
        infoFieldsMissing_other: 'Informationen in {{ count }} Felder fehlen',
        draft: 'Entwurf',
    },
    listingTeaserPrice: {
        detailsButton: 'Einzelheiten',
        offerRange: 'Angebotsbereich:',
    },
    marketingBannerSteelbuyPromise: {
        header: 'Unser SteelBuy Versprechen an Sie',
        promiseSeller: 'Sicherheit – Sie handeln ausschließlich mit geprüften Kunden',
        promiseProcess: 'Full Service – Wir übernehmen Abholung und Lieferung',
        promiseCertificate: 'Qualität – Werkszertifizierungen für alle Materialien',
    },
    inputCharacterCount: {
        inputCharacterRemaining: 'Verbleibende Zeichen: {{remainingCharactersCount}}',
    },
    materialAdditionalComment: {
        description: 'Ergänzen Sie Ihre Materialanfrage bei Bedarf durch zusätzliche Informationen für Verkäufer.',
        placeholder:
            'Bitte geben Sie hier zusätzliche Informationen ein, z.B. zur gewünschten Qualität der Materialien',
        label: 'Kommentare (optional)',
        header: 'Zusätzliche Kommentare',
        characterCount: 'Verbleibende Zeichen: {{remainingCharactersCount}}',
        maxCharacterError: 'Maximale Zeichenanzahl überschritten.',
    },
    materialDimensionSelection: {
        toleranceThicknessLabel: 'Toleranzdicke',
    },
    materialQualitySelection: {
        descriptionLabel: 'Ausführliche Beschreibung',
        descriptionPrimeLabel: 'Detaillierte Beschreibung (optional)',
        descriptionPlaceholder:
            'Bitte beschreiben Sie den genauen Materialzustand, damit Käufer die Qualität beurteilen können.',
        descriptionPrimePlaceholder:
            'Bitte geben Sie hier zusätzliche Informationen an, z. B. das Werk oder den Ursprung des Materials, wenn ein Konformitätsschreiben hochgeladen wird.',
        pictureUploadLabel: 'Bild hochladen (optional)',
        testCertificateLabel: 'Werkszertifizierung hochladen',
        testCertificateLabelOptional: 'Werkszertifizierung hochladen (optional)',
    },
    materialSelection: {
        placeholder: 'Wählen Sie eine Option',
        otherLabel: 'Andere',
    },
    materialInput: {
        placeholder: 'Option auswählen',
    },
    materialOriginSelection: {
        materialOriginSelection: 'West-Europa: EU-Länder, Vereinigtes Königreich, Schweiz, Norwegen',
    },
    materialWeightInput: {
        formItemHeader: 'Gewicht',
        formItemHeaderTheoretical: 'Theoretisches Gewicht',
        weightInputLabel: 'Gesamtgewicht',
        weightInputLabelTheoretical:
            'Bitte beachten Sie, dass Sie diesen Artikel nur auf Basis des theoretischen Gewichts verkaufen.',
        theoreticalWeightNotification:
            'Bitte geben Sie im vorherigen Schritt alle Abmessungen und die Anzahl der Artikel ein, um das theoretische Gesamtgewicht zu berechnen.',
    },
    myAlertsDeletionModal: {
        confirmButtonLabel: 'Ja, löschen Sie es!',
        textContent: 'Sind Sie sicher, dass Sie diese Warnung löschen möchten?',
    },
    makeAnOfferModal: {
        makeAnOffer: 'Preisvorschlag abgeben',
        reviewOffer: 'Bewertungsangebot',
        submitOffer: 'Angebot abgeben',
        review: 'Rezension',
        pricelabel: 'Originalpreis pro {{pricingUnit}}',
        proposedPrice: 'Ihr vorgeschlagener Preis pro {{pricingUnit}}',
        creditLimitExceed: 'Der von Ihnen eingegebene Betrag überschreitet Ihr Kreditlimit.',
        minimumPriceError: 'Bitte geben Sie einen höheren Betrag ein, der dem Marktwert entspricht.',
        priceExceed: 'Der von Ihnen eingegebene Betrag ist höher als der Originalpreis.',
        infoMsg: 'Wir senden Ihnen innerhalb von 24 Stunden ein Update vom Verkäufer.',
        reviewPricingUnit: {
            [PricingUnit.TON]: {
                label: 'Gewicht',
            },
            [PricingUnit.KILOGRAM]: {
                label: 'Gewicht',
            },
            [PricingUnit.SHEET]: {
                label: 'Artikel',
            },
            [PricingUnit.PLATE]: {
                label: 'Artikel',
            },
            [PricingUnit.TREAD]: {
                label: 'Artikel',
            },
        },
        total: 'Gesamt',
        failedToSubmitOffer: 'Angebot konnte nicht übermittelt werden. Bitte versuchen Sie es erneut.',
        existingOffer:
            'Das Angebot kann nicht eingereicht werden, da Sie bereits ein aktives Angebot für diesen Eintrag haben.',
    },
    myAlertsEmptyState: {
        description:
            'Erstellen Sie Benachrichtigungen mit Ihren Suchkriterien, indem Sie nach dem benötigten Material suchen. Wir benachrichtigen Sie dann regelmäßig über passende Ergebnisse.',
        searchButtonLabel: 'Neue Suche starten',
        caption: 'Keine aktiven Warnungen',
    },
    myAlertsList: {
        alertSettingsButtonLabel: 'Einstellungen',
        newResultsSeparatorHeader_zero: 'Keine neuen Ergebnisse (letzte 24 Stunden)',
        newResultsSeparatorHeader_one: '1 Neues Ergebnis (letzte 24 Stunden)',
        newResultsSeparatorHeader_other: '{{count}} Neue Ergebnisse (letzte 24 Stunden)',
        searchSummaryNewResults: '{{value}} neu',
        urgent: 'Dringend',
    },
    myAlertsNeedMore: {
        caption: 'Derzeit gibt es keine Ergebnisse, die Ihren Kriterien entsprechen.',
        description:
            'Sie werden per E-Mail benachrichtigt, sobald das richtige Produkt auf der Plattform verfügbar ist.',
    },
    myAlertsQna: {
        header: 'Fragen und Antworten',
        items: {
            howDoesAnAlertWork: {
                label: 'Wie funktionieren Benachrichtigungen?',
                content:
                    'Suchen Sie nach Material und aktivieren Sie Benachrichtigungen, damit wir Sie per E-Mail benachrichtigen können, sobald ein passendes Material auf SteelBuy erscheint. ',
            },
            howDoUrgentRequestsWork: {
                label: 'Wie funktionieren sofortige Materialanfragen?',
                content:
                    'Erstellen Sie sofort eine Materialanfrage an den Verkäufer und wir benachrichtigen Sie per E-Mail über neue Übereinstimmungsergebnisse. ',
            },
            howDoNotificationsWork: {
                label: 'Wie oft werde ich benachrichtigt?',
                content:
                    'Wenn ein von Ihnen gesuchtes Material auf SteelBuy auftaucht, senden wir Ihnen eine Benachrichtigung per E-Mail an die E-Mail-Adresse Ihres Kontos.',
            },
            howToBestUseAlerts: {
                label: 'Wie nutzt man Benachrichtigungen am besten?',
                content:
                    'Aktivieren Sie Benachrichtigungen für Materialien, die Sie häufig benötigen oder wenn keine Suchergebnisse vorhanden sind. ',
            },
            howToMuteOrDeleteAlerts: {
                label: 'Wie kann ich meine Benachrichtigungen stummschalten oder löschen?',
                content:
                    'Klicken Sie unter „Meine Benachrichtigungen“ auf das Rad rechts neben einer gespeicherten Benachrichtigung, um zu den Benachrichtigungseinstellungen zu gelangen. ',
            },
        },
    },
    numberOfItemsInput: {
        itemLabel: {
            [Product.SHEET]: 'Die Gesamtzahl der Blätter.',
            [Product.PLATE]: 'Die Gesamtzahl der Platten.',
            [Product.TREAD]: 'Die Gesamtzahl der Trittplatten.',
        },
        itemLabelInPackage: {
            [Product.SHEET]: 'Die Gesamtzahl der Bleche im Paket.',
            [Product.PLATE]: 'Die Gesamtzahl der Teller im Paket.',
            [Product.TREAD]: 'Die Gesamtzahl der Trittplatten im Paket.',
        },
    },
    listingDraft: {
        attributeSku: 'Ihre Material-ID',
        attributeWeight: 'Gewicht',
        attributePrice: 'Preis',
        attributeExpiry: 'Ablauf',
        attributeCertificate: 'Werkszertifizierung',
    },
    numberOfItemsLabel: {
        label: {
            [Product.SHEET]: 'Anzahl der Bleche',
            [Product.PLATE]: 'Anzahl der Platten',
            [Product.TREAD]: 'Anzahl der Riffelbleche',
        },
        valueWithLabel: {
            SHEET_zero: '{{count}} Blätter',
            SHEET_one: '1 Blatt',
            SHEET_other: '{{count}} Blätter',
            PLATE_zero: '{{count}} Teller',
            PLATE_one: '1 Teller',
            PLATE_other: '{{count}} Teller',
            TREAD_zero: '{{count}} Teller',
            TREAD_one: '1 Teller',
            TREAD_other: '{{count}} Teller',
        },
        labelShort: {
            [Product.SHEET]: 'Anzahl der Blätter:',
            [Product.PLATE]: 'Anzahl Teller:',
            [Product.TREAD]: 'Anzahl Trittplatten:',
        },
    },
    listingSkuInput: {
        inputHeader: 'Ihre Material-ID',
    },
    orderStatus: {
        [OrderStatus.PROCESSING]: 'Bestellung in Bearbeitung',
        [OrderStatus.FREIGHT_BOOKED]: 'Fracht gebucht',
        [OrderStatus.SHIPPING]: 'Versand',
        [OrderStatus.DELIVERED]: 'Geliefert',
    },
    permissionInformation: {
        basic: {
            searchListing: 'Suche nach Produkten',
            alerts: 'Erstellen Sie Benachrichtigungen',
            drafts: 'Erstellen Sie Angebotsentwürfe',
        },
        seller: {
            submitListing: 'Reichen Sie einen Eintrag ein',
            editRejectedListing: 'Bearbeiten Sie abgelehnte Angebote',
            renewListing: 'Veröffentlichen Sie einen erneuerten Eintrag',
        },
        buyer: {
            purchaseProduct: 'Produkte kaufen',
        },
        manager: {
            editOrganisation: 'Bearbeiten Sie die Organisationsinformationen',
            user: 'Fügen Sie Benutzer hinzu und bearbeiten Sie Benutzerberechtigungen',
            creditLineInformation: 'Sehen Sie sich die Informationen zum Kreditrahmen der Organisation an',
        },
    },
    searchResults: {
        dataHeader: {
            alternativeResultsLabel: 'Alternative Ergebnisse',
            totalResultsLabel_zero: 'Keine Ergebnisse',
            totalResultsLabel_one: '1 Ergebnis',
            totalResultsLabel_other: '{{count}} Ergebnisse',
        },
    },
    searchResultsSorting: {
        inputTitle: 'Sortieren nach',
        options: {
            [SortCriteriaOption.RELEVANCE]: 'Relevanz',
            [SortCriteriaOption.EXPIRES_AT_DESCENDING]: 'Ablaufdatum',
            [SortCriteriaOption.EXPIRES_AT_ASCENDING]: 'Ablaufdatum',
            [SortCriteriaOption.WEIGHT_ASCENDING]: 'Gewicht',
            [SortCriteriaOption.WEIGHT_DESCENDING]: 'Gewicht',
            [SortCriteriaOption.PRICE_ASCENDING]: 'Preis',
            [SortCriteriaOption.PRICE_DESCENDING]: 'Preis',
        },
        placeholderText: 'Wählen Sie eine Option',
    },
    searchResultsTable: {
        newResultsSeparator_zero: 'Keine neuen Ergebnisse (letzte 24 Stunden)',
        newResultsSeparator_one: '1 Neues Ergebnis (letzte 24 Stunden)',
        newResultsSeparator_other: '{{count}} Neue Ergebnisse (letzte 24 Stunden)',
        alternativeResultsSeparator: 'Alternative Ergebnisse',
    },
    sellingPriceInput: {
        formItemHeader: 'Ihr Verkaufspreis pro {{pricingUnit}}',
        inputLabel: 'Diesen Betrag, erhalten Sie, wenn Ihr Material auf SteelBuy verkauft wird (exkl. MwSt.).',
    },
    setPassword: {
        passwordCriteria: {
            minChars: 'Mindestens 16 Zeichen',
            specialChars: 'Ein Sonderzeichen',
            number: 'Eine Zahl',
            lowerCase: 'Ein Kleinbuchstabe',
            upperCase: 'Ein Großbuchstabe',
            matching: 'Passwörter müssen übereinstimmen',
            fieldRequired: 'Dieses Feld ist erforderlich',
        },
        incorrectPassword: 'Falsches Passwort',
        passwordsMatch: 'Passwörter stimmen überein',
        password: 'Passwort',
        newPassword: 'Neues Passwort',
        currentPassword: 'Aktuelles Passwort',
        retypePassword: 'Geben Sie das neue Passwort erneut ein',
        submit: 'Passwort zurücksetzen',
        enterPassword: 'Passwort eingeben',
        enterNewPassword: 'Geben Sie ein neues Passwort ein',
        enterRetypePassword: 'Geben Sie das neue Passwort erneut ein',
        updatePassword: 'Passwort aktualisieren',
    },
    validation: {
        requiredField: 'Dieses Feld ist erforderlich.',
        numberValue: 'Wert ist keine Zahl.',
        numberMinValue: 'Bitte geben Sie eine höhere Zahl ein.',
        numberMaxValue: 'Bitte geben Sie eine niedrigere Zahl ein',
        letterValue: 'Es sind nur Buchstaben erlaubt.',
        fileItemSizeError: 'Datei muss kleiner sein als {{sizeLimitInMegabytes}}MB',
        unsupporttedFileType: 'Nicht unterstützter Dateityp',
        numberLargerThan: 'Muss größer sein als {{min}}.',
    },
};
