import { useMutation } from '@tanstack/react-query';
import axios from '../axios';
import { getCsrfToken } from '../query/account/getCrsfToken';

export type ValidateEmailAddressPayload = {
    token: string;
};

const validateEmailAddress = async (payload: ValidateEmailAddressPayload) => {
    const token = await getCsrfToken();
    const { data } = await axios.post(
        `api/universal/users/email-validation/${payload.token}`,
        {},
        {
            headers: {
                csrfEmailToken: token.csrfEmailToken,
            },
        }
    );
    return data;
};

export const useValidateEmailAddress = () =>
    useMutation({
        mutationFn: (payload: ValidateEmailAddressPayload) => validateEmailAddress(payload),
    });
