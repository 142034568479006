import { useTranslation } from 'react-i18next';
import { AddressModel, Country, getFormattedAddress } from '@steelbuy/ts-shared';
import './Address.scss';

interface AddressProps {
    addressModel?: AddressModel;
    phoneNumber?: string;
}

export const Address = ({ addressModel, phoneNumber }: AddressProps) => {
    const { t } = useTranslation('domainModel');
    const formattedAddress = addressModel && getFormattedAddress(addressModel);
    return (
        <article className="address">
            <span>{addressModel?.organisation.name}</span>
            {formattedAddress?.map(({ value, id, addressLine }) => (
                <span key={id}>{addressLine === 'COUNTRY' ? t(`address.country.${value as Country}`) : value}</span>
            ))}
            {phoneNumber && <span>{phoneNumber}</span>}
        </article>
    );
};
