import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateEmailAddress } from '@steelbuy/data-access';
import { EmailAddressForm } from '@steelbuy/ui-domain';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { ROUTE_ACCOUNT_OVERVIEW } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';

export const AccountEditEmail = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const location = useLocation();
    const { email = '' } = location.state || {};
    const { mutate, isLoading } = useUpdateEmailAddress();

    const onSubmit = (userEmail: string) => {
        mutate(
            { email: userEmail },
            {
                onSuccess: () => {
                    navigate(RoutePath.ACCOUNT, {
                        state: {
                            successNotification: t('application.accountEditEmail.successNotification', {
                                userEmail,
                            }),
                        },
                    });
                },
                onError: () => {
                    navigate(RoutePath.ACCOUNT, {
                        state: {
                            errorNotification: t('application.accountValidateEmail.unableToUpdateEmail'),
                        },
                    });
                },
            }
        );
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.accountEditEmail.pageTitle')}
                previousPageTitle={t('application.accountEditEmail.previousPageTitle')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_OVERVIEW)}
            />
            <FormLayout>
                <EmailAddressForm
                    onCancel={() => {
                        navigate(RoutePath.ACCOUNT);
                    }}
                    onSubmit={onSubmit}
                    defaultEmail={email}
                    isLoading={isLoading}
                />
            </FormLayout>
        </>
    );
};
