import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { formatInputToNumber, validateNumber } from '@steelbuy/ts-shared';

export const useRequiredFieldValidator = () => {
    const { t } = useTranslation('uiDomain');

    return {
        validate: (value: string | ReadonlyArray<string> | number | undefined) => !!value,
        message: t('validation.requiredField'),
    } as const;
};

export const useLettersOnlyValidator = () => {
    const { t } = useTranslation('uiDomain');

    return {
        validate: (value: string | ReadonlyArray<string> | number | undefined) => {
            if (typeof value === 'string') {
                return /^[a-zA-Z ]+$/.test(value);
            }
            return false;
        },
        message: t('validation.letterValue'),
    } as const;
};

export const useNumberValidator = () => {
    const { t } = useTranslation('uiDomain');

    return {
        validate: (value: string | ReadonlyArray<string> | number | undefined) => {
            if (typeof value === 'object') {
                return false;
            }
            if (typeof value === 'number') {
                return validateNumber(`${value}`);
            }
            return validateNumber(value);
        },
        message: t('validation.numberValue'),
    } as const;
};

export const useMinNumberValidator = (minValue: number, message?: string) => {
    const { t } = useTranslation('uiDomain');

    return {
        validate: (value: string | ReadonlyArray<string> | number | undefined) => {
            if (value === undefined || value === '') {
                return true;
            }
            const validator = z.number().min(minValue);
            try {
                const valueAsNumber = formatInputToNumber(`${value}`);
                validator.parse(valueAsNumber);
                return true;
            } catch (e) {
                return false;
            }
        },
        message: message || t('validation.numberMinValue'),
    } as const;
};

export const useMaxNumberValidator = (maxValue: number, message?: string) => {
    const { t } = useTranslation('uiDomain');

    return {
        validate: (value: string | ReadonlyArray<string> | number | undefined) => {
            if (value === undefined || value === '') {
                return true;
            }
            const validator = z.number().max(maxValue);
            try {
                const valueAsNumber = Number(value);
                validator.parse(valueAsNumber);
                return true;
            } catch (e) {
                return false;
            }
        },
        message: message || t('validation.numberMaxValue'),
    } as const;
};
