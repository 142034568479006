import { useTranslation } from 'react-i18next';
import { getFormattedAddress } from '@steelbuy/ts-shared';
import { CustomLink } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { LegalLayout } from '../../views/layouts/legal-layout/LegalLayout';
import { PageHeader } from '../page-header/PageHeader';

import './AcceptableUse.scss';

export const AcceptableUse = () => {
    const { t } = useTranslation('translation');

    const address = {
        addressLine1: `${t('constants.primaryAddress.line2')}, `,
        addressLine2: [
            t('constants.primaryAddress.line1'),
            t('constants.primaryAddress.line3'),
            t('constants.primaryAddress.line4'),
            t('constants.primaryAddress.line5'),
            t('constants.primaryAddress.line6'),
        ].join(', '),
        postalCode: `, ${t('constants.primaryAddress.postcode')}`,
        country: `, ${t('constants.primaryAddress.country')}`,
        name: '',
        city: '',
    };
    return (
        <>
            <PageHeader pageTitle={t('application.acceptabelUsePolicy.headerTitle')} />

            <LegalLayout>
                <div className="acceptable-use" id="top">
                    <h2>ACCEPTABLE USE POLICY</h2>
                    <h4>
                        PLEASE READ THE TERMS OF THIS ACCEPTABLE USE POLICY CAREFULLY BEFORE USING OUR MARKETPLACE
                        AND/OR WEBSITE
                    </h4>

                    <h4>What&apos;s in these terms?</h4>
                    <p>
                        This acceptable use policy (Acceptable Use Policy) sets out the content standards that apply
                        when you upload content to Our Marketplace and/or Our Website, make contact with other users on
                        Our Marketplace and/or Our Website, link to Our Marketplace and/or Our Website, or interact with
                        Our Marketplace and/or Our Website in any other way.
                    </p>

                    <h4>Who We are and how to contact Us</h4>
                    <p>
                        {`www.${t('constants.website')}`} and {`www.${t('constants.marketplace')}`} are operated by
                        Steelbuy Limited (We). We are registered in England and Wales under company number 01914559 and
                        have Our registered office at{' '}
                        {getFormattedAddress(address).map((item) => (
                            <span key={item.id}>{item.value}</span>
                        ))}
                        . This is also Our main trading address. Our VAT number is {t('constants.vatNumber')}.
                    </p>
                    <p>We are a limited company.</p>
                    <p>
                        To contact Us, please email&nbsp;
                        <CustomLink path={`mailto: ${t('constants.emailInfo')}`} isInlineLink isAnchorLink>
                            {t('constants.emailInfo')}
                        </CustomLink>
                        <span> </span>
                        or via telephone on
                        <span> </span>
                        <CustomLink path={`tel:${t('constants.customerServiceTel')}`} isInlineLink isAnchorLink>
                            {t('constants.customerServiceTel')}
                        </CustomLink>
                        .
                    </p>

                    <h4>By using Our Marketplace and/or Our Website you accept these terms</h4>
                    <p>
                        By using Our Marketplace and/or Our Website, you confirm that you accept the terms of this
                        Acceptable Use Policy and that you agree to comply with them.
                    </p>
                    <p>If you do not agree to these terms, you must not use Our Marketplace and/or Our Website.</p>
                    <p>We recommend that you print a copy of these terms for future reference.</p>

                    <h4>There are other terms that may apply to you</h4>
                    <p>
                        <CustomLink path={RoutePath.TERMS_AND_CONDITIONS} isInlineLink>
                            Our Terms of Use
                        </CustomLink>{' '}
                        also apply to your use of Our Marketplace and/or Our Website.
                    </p>

                    <h4>We may make changes to the terms of this Acceptable Use Policy</h4>
                    <p>
                        We amend these terms from time to time. Every time you wish to use Our Marketplace and/or Our
                        Website, please check these terms to ensure you understand the terms that apply at that time.
                    </p>

                    <h4>Prohibited uses</h4>
                    <div className="contents">
                        You may not use Our Marketplace and/or Our Website:
                        <ul>
                            <li>
                                In any way that breaches any applicable local, national or international law or
                                regulation.
                            </li>
                            <li>
                                In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or
                                effect.
                            </li>
                            <li>For the purpose of harming or attempting to harm minors in any way.</li>
                            <li>To bully, insult, intimidate or humiliate any person.</li>
                            <li>
                                To send, knowingly receive, upload, download, use or re-use any material which does not
                                comply with Our content standards below.
                            </li>
                            <li>
                                To transmit, or procure the sending of, any unsolicited or unauthorised advertising or
                                promotional material or any other form of similar solicitation (spam).
                            </li>
                            <li>
                                To knowingly transmit any data, send or upload any material that contains viruses,
                                Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other
                                harmful programs or similar computer code designed to adversely affect the operation of
                                any computer software or hardware.
                            </li>
                            <li>To upload terrorist content.</li>
                        </ul>
                    </div>

                    <div className="contents">
                        You also agree:
                        <ul>
                            <li>
                                Not to reproduce, duplicate, copy or re-sell any part of Our Marketplace and/or Our
                                Website in contravention of the provisions of Our
                                <CustomLink path={RoutePath.TERMS_AND_CONDITIONS} isInlineLink>
                                    Terms of Use
                                </CustomLink>
                                .
                            </li>
                            <li>
                                Not to access without authority, interfere with, damage or disrupt:
                                <ul>
                                    <li>any part of Our Marketplace and/or Our Website;</li>
                                    <li>
                                        any equipment or network on which Our Marketplace and/or Our Website is stored;
                                    </li>
                                    <li>
                                        any software used in the provision of Our Marketplace and/or Our Website; or
                                    </li>
                                    <li>any equipment or network or software owned or used by any third party.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h4>Content standards</h4>
                    <p>
                        These content standards apply to any and all material which you contribute to Our Marketplace
                        and/or Our Website (<b>Contribution</b>
                        ), and to any interactive services associated with them.
                    </p>
                    <p>
                        The content standards must be complied with in spirit as well as to the letter. The content
                        standards apply to each part of any Contribution as well as to its whole.
                    </p>
                    <p>
                        Steelbuy Limited will determine, in its discretion, whether a Contribution breaches the content
                        standards.
                    </p>
                    <div className="contents">
                        A Contribution must:
                        <ul>
                            <li>Be accurate (where it states facts).</li>
                            <li>Be genuinely held (where it states opinions).</li>
                            <li>
                                Comply with the law applicable in England and Wales and in any country from which it is
                                posted.
                            </li>
                        </ul>
                        A Contribution must not:
                        <ul>
                            <li>Be defamatory of any person.</li>
                            <li>Be obscene, offensive, hateful or inflammatory.</li>
                            <li>Bully, insult, intimidate or humiliate.</li>
                            <li>Promote sexually explicit material.</li>
                            <li>Include child sexual abuse material.</li>
                            <li>Promote violence.</li>
                            <li>
                                Promote discrimination based on race, sex, religion, nationality, disability, sexual
                                orientation or age.
                            </li>
                            <li>Infringe any copyright, database right or trade mark of any other person.</li>
                            <li>Be likely to deceive any person.</li>
                            <li>
                                Breach any legal duty owed to a third party, such as a contractual duty or a duty of
                                confidence.
                            </li>
                            <li>Promote any illegal content or activity.</li>
                            <li>Be in contempt of court.</li>
                            <li>
                                Be threatening, abuse or invade another&aops;s privacy, or cause annoyance,
                                inconvenience or needless anxiety.
                            </li>
                            <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
                            <li>
                                Impersonate any person or misrepresent your identity or affiliation with any person.
                            </li>
                            <li>
                                Give the impression that the Contribution emanates from Steelbuy Limited, if this is not
                                the case.
                            </li>
                            <li>
                                Advocate, promote, incite any party to commit, or assist any unlawful or criminal act
                                such as (by way of example only) copyright infringement or computer misuse.
                            </li>
                            <li>
                                Contain a statement which you know or believe, or have reasonable grounds for believing,
                                that members of the public to whom the statement is, or is to be, published are likely
                                to understand as a direct or indirect encouragement or other inducement to the
                                commission, preparation or instigation of acts of terrorism.
                            </li>
                            <li>Contain any advertising or promote any services or web links to other sites.</li>
                        </ul>
                        For the avoidance of doubt, for any Contribution in the form of video content:
                        <ul>
                            <li>
                                You must not upload a video containing any of the following: criminal material (relating
                                to terrorism, sexual exploitation of children, child pornography, racism and
                                xenophobia), unclassified or unclassifiable videos, videos rated R18 or suitable for R18
                                rating and other material that might impair the physical, mental or moral development of
                                persons under the age of 18 (restricted material).
                            </li>
                            <li>You must not upload a video containing harmful material.</li>
                            <li>
                                You must not upload a video containing advertising for any of the following:
                                <ul>
                                    <li>
                                        cigarettes and other tobacco products, electronic cigarettes or electronic
                                        cigarette refill containers, and prescription-only medicine; or
                                    </li>
                                    <li>
                                        for alcoholic drinks that are not aimed specifically at under 18s and do not
                                        encourage immoderate consumption of alcohol.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Any advertising included in a video you upload must not:
                                <ul>
                                    <li>prejudice respect for human dignity;</li>
                                    <li>
                                        include or promote discrimination based on sex, racial or ethnic origin,
                                        nationality, religion or belief, disability, age or sexual orientation;
                                    </li>
                                    <li>encourage behaviour prejudicial to health or safety;</li>
                                    <li>
                                        encourage behaviour grossly prejudicial to the protection of the environment;
                                    </li>
                                    <li>cause physical, mental or moral detriment to persons under the age of 18;</li>
                                    <li>
                                        directly exhort such persons to purchase or rent goods or services in a manner
                                        which exploits their inexperience or credulity;
                                    </li>
                                    <li>
                                        directly encourage such persons to persuade their parents or others to purchase
                                        or rent goods or services;
                                    </li>
                                    <li>exploit the trust of such persons in parents, teachers or others; or</li>
                                    <li>unreasonably show such persons in dangerous situations.</li>
                                </ul>
                            </li>
                            <li>
                                You must use the functionality provided on Our Marketplace and/or Our Website to declare
                                whether, as far as you know or can reasonably be expected to know, any video contains
                                advertising.
                            </li>
                        </ul>
                    </div>

                    <h4>Breach of this Acceptable Use Policy</h4>
                    <p>
                        When We consider that a breach of this Acceptable Use Policy has occurred, We may take such
                        action as We deem appropriate.
                    </p>
                    <div className="contents">
                        Failure to comply with this Acceptable Use Policy constitutes a material breach of the
                        <CustomLink path={RoutePath.TERMS_AND_CONDITIONS} isInlineLink>
                            Terms of Use
                        </CustomLink>{' '}
                        upon which you are permitted to use Our Marketplace and/or Our Website, and may result in Our
                        taking all or any of the following actions:
                        <ul>
                            <li>
                                Immediate, temporary or permanent withdrawal of your right to use Our Marketplace and/or
                                Our Website.
                            </li>
                            <li>
                                Immediate, temporary or permanent removal of any Contribution uploaded by you to Our
                                Marketplace and/or Our Website.
                            </li>
                            <li>Issue of a warning to you.</li>
                            <li>
                                Legal proceedings against you for reimbursement of all costs on an indemnity basis
                                (including, but not limited to, reasonable administrative and legal costs) resulting
                                from the breach.
                            </li>
                            <li>Further legal action against you.</li>
                            <li>
                                Disclosure of such information to law enforcement authorities as We reasonably feel is
                                necessary or as required by law.
                            </li>
                        </ul>
                    </div>
                    <p>
                        We exclude Our liability for all action We may take in response to breaches of this Acceptable
                        Use Policy. The actions We may take are not limited to those described above, and We may take
                        any other action We reasonably deem appropriate.
                    </p>

                    <h4>How this contract can be transferred</h4>
                    <p>
                        We can transfer Our rights and obligations under these terms to any third party, provided this
                        does not adversely affect your rights under these terms.
                    </p>

                    <h4>Which country&apos;s laws apply to any disputes?</h4>
                    <p>
                        The terms of this Acceptable Use Policy, its subject matter and its formation (and any
                        non-contractual disputes or claims) are governed by English law. We both agree to the exclusive
                        jurisdiction of the courts of England and Wales.
                    </p>
                </div>
            </LegalLayout>
        </>
    );
};
