import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    formatCurrency,
    ListingBuyerModel,
    OfferListingStatus,
    getPricingUnit,
    getTradeUnitCalculation,
    TradeUnitCalculation,
    formatNumber,
} from '@steelbuy/ts-shared';
import { Card, CardLayout } from '@steelbuy/ui-primitive';
import { useNumberOfItemsLabel } from '../number-of-items-label/NumberOfItemsLabel';

import './ListingBuyerDetailsPrice.scss';

type ListingBuyerDetailsPriceProps = {
    listing: ListingBuyerModel;
};

export const ListingBuyerDetailsPrice = ({ listing }: ListingBuyerDetailsPriceProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel', 'translation']);
    const numberOfItemsLabel = useNumberOfItemsLabel(listing.material.product);

    const pricingUnit = getPricingUnit(listing.material);

    const acceptedOffer = listing.negotiations
        ?.find(
            (negotiation) =>
                negotiation.status === OfferListingStatus.ACCEPTED ||
                negotiation.status === OfferListingStatus.PURCHASED
        )
        ?.offers?.slice()
        .reverse()?.[0];

    const acceptedOfferPrice = acceptedOffer?.price?.priceIncludingDelivery;

    const weightValue = t(`domainModel:material.tradeUnit.value.${listing.tradeUnit}.quantity`, {
        count: listing.weight,
        formatted: formatNumber(listing.weight),
    });

    const totalOriginalPrice = formatCurrency(listing.pricing.totalExcludingVat.value, listing.pricing.currencyCode);

    const totalAcceptedOfferPrice = formatCurrency(
        (acceptedOfferPrice?.value || 0) *
            (getTradeUnitCalculation(listing.material) === TradeUnitCalculation.BY_WEIGHT
                ? listing.weight
                : listing.numberOfItems || 0),
        listing.pricing.currencyCode
    );

    return (
        <Card isClickable={false}>
            <CardLayout>
                <article className="listing-buyer-details-price">
                    <article className="listing-buyer-details-price__header">
                        {t('uiDomain:listingBuyerDetailsPrice.cardTitle')}
                    </article>
                    <article className="listing-buyer-details-price__content">
                        <article className="listing-buyer-details-price__content__price-per-unit">
                            <article className="listing-buyer-details-price__content__price-per-unit__item">
                                <span className="listing-buyer-details-price__content__price-per-unit__item__label">
                                    {t('uiDomain:listingBuyerDetailsPrice.pricePerUnit', {
                                        pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
                                    })}
                                </span>
                                <span
                                    className={classNames(
                                        'listing-buyer-details-price__content__price-per-unit__item__value',
                                        {
                                            'listing-buyer-details-price__content__price-per-unit__item__value--invalid':
                                                acceptedOfferPrice,
                                        }
                                    )}
                                >
                                    {formatCurrency(
                                        listing.pricing.priceIncludingDelivery.value,
                                        listing.pricing.currencyCode
                                    )}
                                </span>
                                {acceptedOfferPrice && (
                                    <span className="listing-buyer-details-price__content__price-per-unit__item__value">
                                        {formatCurrency(acceptedOfferPrice.value, listing.pricing.currencyCode)}
                                    </span>
                                )}
                            </article>
                        </article>
                        <article className="listing-buyer-details-price__content__total-price">
                            {numberOfItemsLabel && (
                                <article className="listing-buyer-details-price__content__total-price__item">
                                    <span className="listing-buyer-details-price__content__total-price__item__label">
                                        {numberOfItemsLabel}
                                    </span>
                                    <span className="listing-buyer-details-price__content__total-price__item__value">
                                        {listing.numberOfItems}
                                    </span>
                                </article>
                            )}
                            <article className="listing-buyer-details-price__content__total-price__item">
                                <span className="listing-buyer-details-price__content__total-price__item__label">
                                    {t('translation:application.checkout.weight')}
                                </span>
                                <span className="listing-buyer-details-price__content__total-price__item__value">
                                    {weightValue}
                                </span>
                            </article>
                            <article className="listing-buyer-details-price__content__total-price__item">
                                <span className="listing-buyer-details-price__content__total-price__item__label">
                                    {t('uiDomain:listingBuyerDetailsPrice.totalExclVat')}
                                </span>
                                <article className="listing-buyer-details-price__content__total-price__item__value">
                                    {acceptedOfferPrice && (
                                        <span className="listing-buyer-details-price__content__total-price__item__value__original-price">
                                            {totalOriginalPrice}
                                        </span>
                                    )}
                                    <span className="listing-buyer-details-price__content__total-price__item__value__purchase-price">
                                        {acceptedOfferPrice ? totalAcceptedOfferPrice : totalOriginalPrice}
                                    </span>
                                </article>
                            </article>
                        </article>
                    </article>
                </article>
            </CardLayout>
        </Card>
    );
};
