import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useUserSelfDetails, useValidateEmailAddress } from '@steelbuy/data-access';
import { LoadingSpinnerOverlay } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

export const AccountEmailValidation = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { token } = useParams();
    const { refetch } = useUserSelfDetails({ queryOptions: { enabled: true } });
    const { mutate } = useValidateEmailAddress();

    useEffect(() => {
        if (!token) {
            navigate(RoutePath.ACCOUNT, {
                state: {
                    errorNotification: t('application.accountValidateEmail.unableToUpdateEmail'),
                },
            });
            return;
        }
        (async () => {
            mutate(
                { token },
                {
                    onSuccess: () => {
                        refetch();
                        navigate(RoutePath.ACCOUNT, {
                            state: {
                                successNotification: t('application.accountValidateEmail.emailUpdated'),
                            },
                        });
                    },
                    onError: () => {
                        navigate(RoutePath.ACCOUNT, {
                            state: {
                                errorNotification: t('application.accountValidateEmail.unableToUpdateEmail'),
                            },
                        });
                    },
                }
            );
        })();
    }, []);

    return <LoadingSpinnerOverlay />;
};
