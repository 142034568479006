import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TradeUnit,
    TradeUnitCalculation,
    formatInputToNumber,
    getNumberInputPattern,
    formatNumberForInput,
} from '@steelbuy/ts-shared';
import { FormItem, InputTextfieldUnit } from '@steelbuy/ui-primitive';

import { RequiredFieldValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator, useNumberValidator } from '../validation/ValidationHooks';

type MaterialWeightInputProps = {
    initialWeight?: string;
    setWeight: (newWeight?: number) => void;
    tradeUnit?: TradeUnit;
    label?: string;
    tradeUnitCalculation?: TradeUnitCalculation;
    theoreticalWeight?: number;
} & RequiredFieldValidationProps;

export const MaterialWeightInput = ({
    initialWeight,
    setWeight,
    tradeUnit = TradeUnit.TON,
    forceValidation,
    label,
    tradeUnitCalculation,
    theoreticalWeight,
}: MaterialWeightInputProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();
    const numberValidator = useNumberValidator();
    const isTheoreticalWeight = tradeUnitCalculation === TradeUnitCalculation.BY_ITEM;

    const [weightState, setWeightState] = useState<string | undefined>(initialWeight);

    const handleWeightChange = (newWeight: string) => {
        setWeightState(newWeight);
        const weightAsFloat = formatInputToNumber(newWeight);
        if (newWeight && !Number.isNaN(weightAsFloat)) {
            setWeight(weightAsFloat);
        } else {
            setWeight(undefined);
        }
    };

    return (
        <FormItem
            header={t(
                `uiDomain:materialWeightInput.${isTheoreticalWeight ? 'formItemHeaderTheoretical' : 'formItemHeader'}`
            )}
        >
            <InputTextfieldUnit
                unit={t(`domainModel:material.tradeUnit.value.${tradeUnit}.label`)}
                label={
                    label ||
                    t(
                        `uiDomain:materialWeightInput.${
                            isTheoreticalWeight ? 'weightInputLabelTheoretical' : 'weightInputLabel'
                        }`
                    )
                }
                pattern={getNumberInputPattern()}
                name="weight"
                value={isTheoreticalWeight ? formatNumberForInput(theoreticalWeight) : weightState ?? ''}
                onChange={handleWeightChange}
                validators={isTheoreticalWeight ? [] : [requiredFieldValidator, numberValidator]}
                forceValidation={forceValidation}
                disabled={isTheoreticalWeight}
                readOnly={isTheoreticalWeight}
                isMandatory={!isTheoreticalWeight}
            />
        </FormItem>
    );
};
