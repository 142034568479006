import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useCounterOfferMutation } from '@steelbuy/data-access';
import { Modal, useModal } from '@steelbuy/modal-dialog';
import {
    CurrencyCode,
    formatCurrency,
    formatInputToNumber,
    MonetaryAmount,
    OfferView,
    PricingUnit,
} from '@steelbuy/ts-shared';
import { MonetaryAmountInput } from '@steelbuy/ui-domain';
import {
    ButtonPrimary,
    ButtonTertiary,
    LoadingStatus,
    ModalContent,
    ModalContentActionBar,
    ModalContentMain,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { useBlurEffect } from '@steelbuy/util';
import { getTimeLeftText } from '../OffersUtil';
import './OfferCounterModal.scss';

interface OfferCounterModalProps {
    show: boolean;
    negotiationId: string;
    onModalClose: () => void;
    onStatusChange: (message?: string, errorMessage?: string) => void;
    originalPrice: number;
    currencyCode: CurrencyCode;
    latestOfferPrice: number;
    pricingUnit: PricingUnit;
    weightOrItems: number;
    expiresAt: string;
    creditLimit: number | undefined;
    view: OfferView;
    taxPercent: number;
    minimumPrice: number;
}

export const OfferCounterModal = ({
    show,
    negotiationId,
    onModalClose,
    onStatusChange,
    originalPrice,
    currencyCode,
    latestOfferPrice,
    pricingUnit,
    weightOrItems,
    creditLimit,
    expiresAt,
    view,
    taxPercent,
    minimumPrice,
}: OfferCounterModalProps) => {
    const { t } = useTranslation(['translation', 'uiDomain', 'domainModel']);

    const pricingUnitTranslation = t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`);

    let weightOrItemsText = t(`domainModel:material.pricingUnit.value.${pricingUnit}.quantity_other`, {
        count: weightOrItems,
    });
    if (weightOrItems === 0) {
        weightOrItemsText = t(`domainModel:material.pricingUnit.value.${pricingUnit}.quantity_zero`);
    } else if (weightOrItems === 1) {
        weightOrItemsText = t(`domainModel:material.pricingUnit.value.${pricingUnit}.quantity_one`);
    }
    const [priceValue, setPriceValue] = useState<MonetaryAmount | undefined>();
    const [isReview, setIsReview] = useState<boolean>(false);
    const timeLeft = getTimeLeftText(expiresAt, t);
    const offerCounterModal = useModal(onModalClose);
    const { isLoading, mutate } = useCounterOfferMutation();

    const originalPriceAsPounds = originalPrice && originalPrice / 100;
    const creditLimitAsPounds = creditLimit ? creditLimit / 100 : creditLimit;
    const maxOfferPerUnitForCredit =
        creditLimitAsPounds !== undefined && view === OfferView.BUYER
            ? ((creditLimitAsPounds / (100 + taxPercent)) * 100) / weightOrItems
            : Infinity;

    const handleCounterOfferSubmit = () => {
        mutate(
            { negotiationId, price: priceValue?.value || 0 },
            {
                onSuccess: () => {
                    offerCounterModal.hide();
                    onStatusChange(
                        view === OfferView.SELLER
                            ? t('application.offers.counterMsgSeller')
                            : t('application.offers.counterMsgBuyer')
                    );
                },
                onError: () => {
                    offerCounterModal.hide();
                    onStatusChange(undefined, t('application.offers.counterErrorMSg'));
                },
            }
        );
    };

    useEffect(() => {
        if (show) {
            offerCounterModal.show();
        } else {
            offerCounterModal.hide();
        }
    }, [show]);

    useBlurEffect([isReview]);

    const schema = z.object({
        offer: z
            .string({ required_error: t('uiDomain:validation.requiredField') })
            .nonempty(t('uiDomain:validation.requiredField'))
            .transform((value) => formatInputToNumber(value))
            .pipe(
                z.coerce
                    .number({
                        invalid_type_error: t('uiDomain:validation.numberValue'),
                    })
                    .min(minimumPrice, t('application.offers.minimumPriceError'))
                    .max(originalPriceAsPounds - 0.01, t('application.offers.priceExceed'))
                    .max(maxOfferPerUnitForCredit, t('application.offers.limitPriceExceed'))
            ),
    });

    const {
        control,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        reset,
    } = useForm<z.infer<typeof schema>>({
        defaultValues: {
            offer: undefined,
        },
        resolver: zodResolver(schema),
        mode: 'all',
    });

    const onClose = () => {
        offerCounterModal.hide();
        setIsReview(false);
        setPriceValue(undefined);
        reset();
        onModalClose();
    };

    return (
        <Modal modalAccessor={offerCounterModal}>
            <form onSubmit={handleSubmit(async () => {})}>
                <ModalContent
                    onCloseModal={onClose}
                    headline={
                        isReview ? t('application.offers.reviewCounterOffer') : t('application.offers.counterOffer')
                    }
                >
                    <ModalContentMain>
                        <div className="counter-offer-modal">
                            {isReview ? (
                                <>
                                    <Notification
                                        level={NotificationLevel.INFO}
                                        message={t(
                                            view === OfferView.SELLER
                                                ? 'application.offers.buyerCounterInfoMsg'
                                                : 'application.offers.sellerCounterInfoMsg',
                                            {
                                                timeLeft,
                                            }
                                        )}
                                        stayOpen
                                    />

                                    <div className="counter-offer-modal__container">
                                        <div className="counter-offer-modal__container__proposed-price">
                                            <div className="counter-offer-modal__sub-container">
                                                <p className="counter-offer-modal__title">
                                                    {t('application.offers.proposedPrice', {
                                                        pricingUnit: pricingUnitTranslation,
                                                    })}
                                                </p>
                                            </div>
                                            <div className="counter-offer-modal__container__proposed-price__column">
                                                <p className="counter-offer-modal__sub-text">
                                                    {formatCurrency(priceValue?.value || 0, currencyCode)}
                                                </p>
                                            </div>
                                            <div className="counter-offer-modal__sub-container">
                                                <p className="counter-offer-modal__title">
                                                    {t(`application.offers.reviewPricingUnit.${pricingUnit}.label`)}
                                                </p>
                                            </div>
                                            <div className="counter-offer-modal__container__proposed-price__column">
                                                <p className="counter-offer-modal__sub-text">{weightOrItemsText}</p>
                                            </div>
                                        </div>
                                        <div className="counter-offer-modal__total">
                                            <p className="counter-offer-modal__title">
                                                {t('translation:application.offers.total')}
                                            </p>
                                            <p className="counter-offer-modal__price-total">
                                                {formatCurrency(weightOrItems * (priceValue?.value || 0), currencyCode)}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="counter-offer-modal__title">
                                        {t('application.offers.pricelabel', {
                                            pricingUnit: pricingUnitTranslation,
                                        })}
                                    </p>
                                    <div className="counter-offer-modal__price">
                                        {formatCurrency(originalPrice, currencyCode)}
                                    </div>
                                    <p className="counter-offer-modal__title">
                                        {t(
                                            view === OfferView.SELLER
                                                ? 'application.offers.buyerOfferedPriceLabel'
                                                : 'application.offers.sellerOfferedPriceLabel',
                                            {
                                                pricingUnit: pricingUnitTranslation,
                                            }
                                        )}
                                    </p>
                                    <div className="counter-offer-modal__price">
                                        {formatCurrency(latestOfferPrice, currencyCode)}
                                    </div>

                                    <Controller
                                        name="offer"
                                        control={control}
                                        render={({ field: { onChange, onBlur } }) => (
                                            <MonetaryAmountInput
                                                initialMonetaryAmount={priceValue}
                                                currencyCode={currencyCode}
                                                updateMonetaryAmount={setPriceValue}
                                                label={t('application.offers.proposedPrice', {
                                                    pricingUnit: pricingUnitTranslation,
                                                })}
                                                name="counter-offer-price"
                                                showPricingUnit={false}
                                                error={errors.offer?.message}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                testId="offer-price"
                                            />
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </ModalContentMain>
                    <div className="counter-offer-action-bar">
                        <ModalContentActionBar>
                            {isReview ? (
                                <>
                                    <ButtonTertiary
                                        disabled={isLoading}
                                        label={t('uiDomain:common.edit')}
                                        onClick={() => setIsReview(false)}
                                    />
                                    <ButtonPrimary
                                        disabled={isLoading}
                                        label={t('application.offers.submitCounterOffer')}
                                        onClick={() => {
                                            if (priceValue?.value) {
                                                handleCounterOfferSubmit();
                                            }
                                        }}
                                        loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                                    />
                                </>
                            ) : (
                                <>
                                    <ButtonTertiary label={t('uiDomain:common.cancel')} onClick={onClose} />
                                    <ButtonPrimary
                                        disabled={!isValid || !isDirty}
                                        label={t('application.offers.review')}
                                        onClick={() => setIsReview(true)}
                                    />
                                </>
                            )}
                        </ModalContentActionBar>
                    </div>
                </ModalContent>
            </form>
        </Modal>
    );
};
