import { FileUploadStatus } from '../lib/input-file/input-file-item/InputFileItem';

export default {
    checkoutDeliveryOption: {
        headerLabel: 'Lieferoption',
        headerDescriptionLabel: 'Sollte SteelBuy alle Artikel an eine oder mehrere Adressen liefern?',
        singleAddressLabel: 'Einzeladresse',
        singleAddressDescriptionLabel: 'Ihre Bestellung wird an eine Adresse versendet.',
        multipleAddressLabel: 'Mehrere Adressen',
        multipleAddressDescriptionLabel: 'Ihre Bestellung wird an mehrere Adressen versendet.',
    },
    checkout: {
        delivery: {
            title: 'Lieferung',
            haulierRequiredMessage: 'Müssen wir für diesen Artikel einen Liefertermin buchen?',
            checkboxYes: 'Ja',
            checkboxNo: 'NEIN',
            materialLocationLabel: 'Materialstandort',
        },
        deliveryText: 'Voraussichtliche Lieferzeit',
        payment: {
            optionCreditLabel: 'Kredit',
            optionCreditExplanation: 'Ihre Bestellung wird direkt versendet.',
            optionUpfrontLabel: 'Vorauszahlung',
            optionUpfrontExplanation: 'Ihre Bestellung wird versendet, nachdem wir Ihre Zahlung erhalten haben.',
        },
        shippingCostsLabel: 'Lieferung',
        totalExclVat: 'Gesamt (exkl. MwSt.)',
        itemsTotalExclVat: 'Artikel Gesamt (exkl. MwSt.)',
        totalInclVat: 'Gesamt (inkl. MwSt.)',
        vatLabel: '+ {{value}}% MwSt',
        weight: 'Gewicht',
        theoreticalWeight: 'Theoretisches Gewicht',
    },
    fileInput: {
        showMoreLabel: 'Mehr anzeigen',
        showLessLabel: 'Weniger anzeigen',
        fileInputLabel: 'Bitte wählen Sie die Datei(en) zum Hochladen aus',
        filesUploaded: '{{uploadedCount}} von {{totalFiles}} hochgeladen',
        filesizeKB: '{{size}}KB',
        filesizeMB: '{{size}}MB',
        sizeAndStatus: '{{size}} - {{status}}',
        fileUploadStatus: {
            [FileUploadStatus.Uploading]: 'Hochladen',
            [FileUploadStatus.Error]: 'Fehler',
            [FileUploadStatus.Success]: 'Hochgeladen',
        },
        browse: 'Durchsuchen',
    },
};
