import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ButtonCallToAction, MarketingBannerContact } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { PageHeader } from '../page-header/PageHeader';
import './CheckoutSuccess.scss';

type CheckoutSuccessWrapperProps = {
    children: ReactNode;
};

export const CheckoutSuccessWrapper = ({ children }: CheckoutSuccessWrapperProps) => {
    const { t } = useTranslation('translation');

    const mailAddress = t('application.checkoutSuccess.banner.emailAddress');

    useEffect(() => {
        scrollMainLayout(0, 0);
    }, []);

    return (
        <>
            <PageHeader pageTitle={t('application.checkoutSuccess.header.title')} showCartIcon={false} />

            <div className="checkout-success">
                <FormLayout>
                    {children}
                    <>
                        <MarketingBannerContact
                            header={t('application.checkoutSuccess.banner.headline')}
                            text={t('application.checkoutSuccess.banner.contactText')}
                        >
                            <a href={`mailto:${mailAddress}`}>{mailAddress}</a>
                        </MarketingBannerContact>
                        <section className="checkout-success__bottom-buttons">
                            <Link to={RoutePath.SEARCH_LISTINGS}>
                                <ButtonCallToAction
                                    label={t('application.checkoutSuccess.searchListingsButtonLabel')}
                                />
                            </Link>
                        </section>
                    </>
                </FormLayout>
            </div>
        </>
    );
};
