/* eslint-disable react/destructuring-assignment */
// Rule disabled because type narrowing and destructuring is not possible
import { zodResolver } from '@hookform/resolvers/zod';
import i18next from 'i18next';
import { useEffect } from 'react';
import { Controller, useForm, ValidateResult } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { getPasswordLowerCasePattern, getPasswordUpperCasePattern, getUserLanguage } from '@steelbuy/ts-shared';
import {
    ButtonCallToAction,
    ButtonPrimary,
    ButtonSecondary,
    FormActionbar,
    FormItem,
    LoadingStatus,
    Notification,
    NotificationLevel,
    ToLink,
} from '@steelbuy/ui-primitive';
import { InputPassword } from '../input-password/InputPassword';
import { ValidationMessage } from '../validation-message/ValidationMessage';

import './SetPasswordForm.scss';

type SetPasswordFormProps =
    | {
          onSubmit: (password: string, oldPassword: string) => void;
          isLoading: boolean;
          error?: string;
          notificationLink?: ToLink;
          isOldPasswordRequired: true;
          oldPasswordError: boolean;
      }
    | {
          onSubmit: (password: string) => void;
          isLoading: boolean;
          error?: string;
          notificationLink?: ToLink;
          isOldPasswordRequired?: false;
          oldPasswordError?: false;
      };

export const SetPasswordForm = (props: SetPasswordFormProps) => {
    const isOldPasswordRequired = props.isOldPasswordRequired || false;
    const { t } = useTranslation('uiDomain');
    const navigate = useNavigate();

    const literal = 'setPassword';

    const literals = {
        minChars: t(`${literal}.passwordCriteria.minChars`),
        specialChars: t(`${literal}.passwordCriteria.specialChars`),
        number: t(`${literal}.passwordCriteria.number`),
        lowerCase: t(`${literal}.passwordCriteria.lowerCase`),
        upperCase: t(`${literal}.passwordCriteria.upperCase`),
        matching: t(`${literal}.passwordCriteria.matching`),
        fieldRequired: t(`${literal}.passwordCriteria.fieldRequired`),
    };

    const errorsList = [
        literals.lowerCase,
        literals.upperCase,
        literals.number,
        literals.specialChars,
        literals.minChars,
    ];

    const schema = z
        .object({
            newPassword: z
                .string()
                .min(16, literals.minChars)
                .regex(/[$*.[\]{}()?\-"!@#%&,><':;|_~`+=\\/]+/g, literals.specialChars)
                .regex(/\d+/, literals.number)
                .regex(getPasswordLowerCasePattern(getUserLanguage([i18next.language])), literals.lowerCase)
                .regex(getPasswordUpperCasePattern(getUserLanguage([i18next.language])), literals.upperCase),
            retypeNewPassword: z.string().nonempty(literals.fieldRequired),
            ...(isOldPasswordRequired && { oldPassword: z.string().nonempty(literals.fieldRequired) }),
        })
        .superRefine(({ newPassword, retypeNewPassword }, ctx) => {
            if (newPassword !== retypeNewPassword) {
                ctx.addIssue({
                    code: 'custom',
                    message: literals.matching,
                    path: ['retypeNewPassword'],
                });
            }
        });

    const {
        control,
        trigger,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors, isValid, touchedFields, dirtyFields },
    } = useForm({
        defaultValues: {
            newPassword: '',
            retypeNewPassword: '',
            ...(isOldPasswordRequired && { oldPassword: '' }),
        },
        resolver: zodResolver(schema),
        criteriaMode: 'all',
        mode: 'all',
    });

    // TODO Look into union type for react hook form so oldPassword is not string|undefined
    const onSubmitClick = handleSubmit(({ newPassword, oldPassword }) => {
        if (props.isOldPasswordRequired) {
            oldPassword && props.onSubmit(newPassword, oldPassword);
        } else {
            props.onSubmit(newPassword);
        }
    });

    // Force validation to run on ComponentDidMount
    useEffect(() => {
        trigger(['newPassword']).then();
    }, [i18next.language]);

    useEffect(() => {
        if (isOldPasswordRequired) {
            if (props.oldPasswordError) {
                setError('oldPassword', { type: 'custom', message: t(`${literal}.incorrectPassword`) });
            } else {
                clearErrors('oldPassword');
            }
        }
    }, [props.oldPasswordError]);
    return (
        <form onSubmit={onSubmitClick} className="set-password-form">
            <FormItem hideTextWrapper={isOldPasswordRequired}>
                <div className="set-password-form__form-input">
                    {isOldPasswordRequired && (
                        <Controller
                            name="oldPassword"
                            control={control}
                            render={({ field: { onChange, onBlur } }) => (
                                <InputPassword
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={errors.oldPassword?.message}
                                    label={t(`${literal}.currentPassword`)}
                                    placeholder="Enter current password"
                                />
                            )}
                        />
                    )}
                </div>
                <div className="set-password-form__form-input">
                    <Controller
                        name="newPassword"
                        control={control}
                        render={({ field: { onChange, onBlur } }) => (
                            <InputPassword
                                onChange={(e) => {
                                    onChange(e);
                                    touchedFields.retypeNewPassword && trigger('retypeNewPassword');
                                }}
                                onBlur={onBlur}
                                label={isOldPasswordRequired ? t(`${literal}.newPassword`) : t(`${literal}.password`)}
                                placeholder={
                                    isOldPasswordRequired
                                        ? t(`${literal}.enterNewPassword`)
                                        : t(`${literal}.enterPassword`)
                                }
                                error={
                                    touchedFields.newPassword && errors.newPassword?.message !== undefined
                                        ? ' '
                                        : undefined
                                }
                            />
                        )}
                    />
                    {errorsList.map((errorMessage) => (
                        <ValidationMessage
                            key={errorMessage}
                            text={errorMessage}
                            success={
                                !errors.newPassword ||
                                !Object.values(errors?.newPassword?.types ?? [])
                                    .reduce((acc, val) => acc.concat(val), [] as Array<ValidateResult>)
                                    .includes(errorMessage)
                            }
                        />
                    ))}
                </div>

                <div className="set-password-form__form-input">
                    <Controller
                        name="retypeNewPassword"
                        control={control}
                        render={({ field: { onChange, onBlur } }) => (
                            <InputPassword
                                label={t(`${literal}.retypePassword`)}
                                onChange={onChange}
                                error={errors.retypeNewPassword?.message}
                                onBlur={onBlur}
                                placeholder={t(`${literal}.enterRetypePassword`)}
                            />
                        )}
                    />
                    {touchedFields.retypeNewPassword && !errors.retypeNewPassword && dirtyFields.newPassword && (
                        <ValidationMessage text={t(`${literal}.passwordsMatch`)} success />
                    )}
                </div>
            </FormItem>
            {props.error && (
                <Notification
                    level={NotificationLevel.ERROR}
                    message={props.error}
                    stayOpen
                    link={props.notificationLink}
                />
            )}

            {isOldPasswordRequired ? (
                <div className="set-password-form__buttons">
                    <ButtonSecondary label={t('common.cancel')} onClick={() => navigate('/account')} />
                    <ButtonPrimary
                        label={!isOldPasswordRequired ? t(`${literal}.submit`) : t(`${literal}.updatePassword`)}
                        disabled={props.isLoading || !isValid}
                        type="submit"
                        loadingStatus={props.isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                    />
                </div>
            ) : (
                <FormActionbar>
                    <ButtonCallToAction
                        label={!isOldPasswordRequired ? t(`${literal}.submit`) : t(`${literal}.updatePassword`)}
                        disabled={props.isLoading || !isValid}
                        type="submit"
                        loadingStatus={props.isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                    />
                </FormActionbar>
            )}
        </form>
    );
};
