import { useTranslation } from 'react-i18next';
import { OfferListingStatus, OfferView } from '@steelbuy/ts-shared';
import { ButtonSize, ButtonTertiary, ButtonGhostSystemOnLightS, ButtonColor } from '@steelbuy/ui-primitive';
import { ModalView } from './OfferModals';

export const OffersActionButtons = ({
    displayInGrid,
    setActiveModal,
    view,
    status,
}: {
    displayInGrid?: boolean;
    setActiveModal: (modalView: ModalView) => void;
    view: OfferView;
    status: OfferListingStatus;
}) => {
    const { t } = useTranslation('translation');

    const showWithdrawOptions =
        (view === OfferView.BUYER && status === OfferListingStatus.AWAITING_SELLER) ||
        (view === OfferView.SELLER && status === OfferListingStatus.AWAITING_BUYER);

    const showResponseOptions =
        (view === OfferView.SELLER && status === OfferListingStatus.AWAITING_SELLER) ||
        (view === OfferView.BUYER && status === OfferListingStatus.AWAITING_BUYER);

    return displayInGrid ? (
        <div className="offers__grid__body__buttongroup">
            {showWithdrawOptions && (
                <ButtonTertiary
                    size={ButtonSize.S}
                    label={t('application.offers.withdraw')}
                    onClick={() => setActiveModal(ModalView.WITHDRAW)}
                />
            )}
            {showResponseOptions && (
                <>
                    <ButtonGhostSystemOnLightS
                        onClick={() => setActiveModal(ModalView.REJECTED)}
                        label={t('application.offers.reject')}
                    />
                    <ButtonTertiary
                        size={ButtonSize.S}
                        label={t('application.offers.counter')}
                        onClick={() => setActiveModal(ModalView.COUNTER)}
                    />
                    <ButtonTertiary
                        size={ButtonSize.S}
                        label={t('application.offers.accept')}
                        onClick={() => setActiveModal(ModalView.ACCEPTED)}
                        color={ButtonColor.DARK}
                    />
                </>
            )}
        </div>
    ) : (
        <div className="offers__button-group-sm">
            {showWithdrawOptions && (
                <ButtonTertiary
                    size={ButtonSize.M}
                    label={t('application.offers.withdraw')}
                    onClick={() => setActiveModal(ModalView.WITHDRAW)}
                />
            )}
            {showResponseOptions && (
                <>
                    <ButtonTertiary
                        size={ButtonSize.M}
                        onClick={() => setActiveModal(ModalView.REJECTED)}
                        label={t('application.offers.reject')}
                    />
                    <ButtonTertiary
                        size={ButtonSize.M}
                        label={t('application.offers.counter')}
                        onClick={() => setActiveModal(ModalView.COUNTER)}
                    />
                    <ButtonTertiary
                        size={ButtonSize.M}
                        label={t('application.offers.accept')}
                        onClick={() => setActiveModal(ModalView.ACCEPTED)}
                        color={ButtonColor.DARK}
                    />
                </>
            )}
        </div>
    );
};
