import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TrackingEvent, useAnalytics } from '@steelbuy/analytics';
import { useFeatureFlag } from '@steelbuy/data-access';
import {
    Feature,
    getPricingUnit,
    getUrgentRequestMandatoryFields,
    ListingBuyerAlertModel,
    SearchFormData,
} from '@steelbuy/ts-shared';
import { SearchSummary, toSearchFormData } from '@steelbuy/ui-domain';
import {
    ButtonPrimary,
    CardClear,
    SearchSummaryActions,
    SearchSummaryResults,
    Separator,
    SeparatorIdentifier,
} from '@steelbuy/ui-primitive';
import { entries } from '@steelbuy/util';
import { RoutePath } from '../../router/Routes';

import './UrgentRequestsList.scss';
import { InitialCreateListingData } from '../create-listing/CreateListingContextUtil';

interface UrgentRequestListProps {
    urgentRequests: ListingBuyerAlertModel[];
    origin: RoutePath;
    showSeperators?: boolean;
    showSymbols?: boolean;
    newUrgentRequestsTotal?: number;
}

const isMaterialStepField = (searchFormField: keyof SearchFormData) => {
    switch (searchFormField) {
        case 'materialType':
        case 'shape':
        case 'product':
        case 'definition':
        case 'grade':
        case 'coating':
            return true;
        default:
            return false;
    }
};

const isDimensionStepField = (searchFormField: keyof SearchFormData) => {
    switch (searchFormField) {
        case 'thickness':
        case 'width':
            return true;
        default:
            return false;
    }
};

const isWeightPriceStepField = (searchFormField: keyof SearchFormData) => searchFormField === 'weight';

const getCreateListingData = (searchFormData: SearchFormData): InitialCreateListingData =>
    entries(searchFormData).reduce(
        (acc, [field, fieldValue]) => {
            if (field && fieldValue && getUrgentRequestMandatoryFields(searchFormData).includes(field)) {
                if (isMaterialStepField(field)) {
                    return {
                        ...acc,
                        materialStepData: {
                            ...acc.materialStepData,
                            [field]: fieldValue,
                        },
                    };
                }
                if (isDimensionStepField(field)) {
                    return {
                        ...acc,
                        dimensionsStepData: {
                            ...acc.dimensionsStepData,
                            [field]: parseFloat(`${fieldValue}`),
                        },
                    };
                }
                if (isWeightPriceStepField(field)) {
                    return {
                        ...acc,
                        weightPriceStepData: {
                            ...acc.weightPriceStepData,
                            [field]: parseFloat(`${fieldValue}`),
                        },
                    };
                }
            }
            return acc;
        },
        {
            materialStepData: {},
            dimensionsStepData: {},
            weightPriceStepData: {},
        } as InitialCreateListingData
    );

export const UrgentRequestsList = ({
    urgentRequests,
    origin,
    showSeperators = true,
    showSymbols = true,
    newUrgentRequestsTotal,
}: UrgentRequestListProps) => {
    const { t } = useTranslation('translation');
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const { isFeatureSupported } = useFeatureFlag();

    const createListing = (searchFormData: SearchFormData, alertId: string) => {
        analytics.sendEvent(TrackingEvent.CREATE_IMR_LISTING, {
            alertId,
            created: new Date().toISOString(),
            material: searchFormData.materialType,
        });
        navigate(RoutePath.CREATE_LISTING_WIZARD, {
            state: {
                ...getCreateListingData(searchFormData),
                origin,
                alertId,
                targetPrice: searchFormData.targetPrice ? parseFloat(searchFormData.targetPrice) : undefined,
                targetPricingUnit: getPricingUnit(searchFormData),
            },
        });
    };

    const totalRequests = !!newUrgentRequestsTotal;

    return (
        <div className="urgent-requests-list">
            {urgentRequests.map(({ filterQuery, id, comment }, index) => (
                <Fragment key={`urgent-request-${id}`}>
                    <CardClear isClickable={false}>
                        <SearchSummary
                            searchFormData={toSearchFormData(filterQuery)}
                            showSymbols={showSymbols}
                            comment={comment}
                            showPrice={isFeatureSupported(Feature.IMR_TARGET_PRICE)}
                        >
                            <SearchSummaryActions>
                                {totalRequests && index < newUrgentRequestsTotal && (
                                    <SearchSummaryResults results={t('application.urgentRequests.new')} />
                                )}
                                <ButtonPrimary
                                    label={t('application.urgentRequests.createListing')}
                                    onClick={() => createListing(toSearchFormData(filterQuery), id)}
                                />
                            </SearchSummaryActions>
                        </SearchSummary>
                    </CardClear>
                    {showSeperators && <Separator separatorType={SeparatorIdentifier.GROUP_ON_LIGHT} />}
                </Fragment>
            ))}
        </div>
    );
};
