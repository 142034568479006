import { OrderModel } from '@steelbuy/data-access';
import { CheckoutIllustratedSuccess } from '@steelbuy/ui-domain';
import { CheckoutSuccessWrapper } from './CheckoutSuccessWrapper';
import { RoutePath } from '../../router/Routes';

export const CheckoutSuccessMyCart = ({ orderPlaced }: { orderPlaced: OrderModel }) => (
    <CheckoutSuccessWrapper>
        <CheckoutIllustratedSuccess orderModelCart={orderPlaced} myOrdersUrl={RoutePath.MY_ORDERS} isCart />
    </CheckoutSuccessWrapper>
);
