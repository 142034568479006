import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location } from 'react-router-dom';
import { useFeatureFlag } from '@steelbuy/data-access';
import { ActionStatus, useListingSellerDraftCollectionData } from '@steelbuy/data-provider';
import { Feature, Product } from '@steelbuy/ts-shared';
import { useBlocker } from '@steelbuy/ui-domain';
import { ButtonPrimary } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { CreateUnsavedChangesModal } from '../common-utils/CreateUnsavedChangesModal';
import { useCreateListingContext } from '../create-listing/CreateListingContext';
import { CreateListingSaveDraftButton } from '../create-listing/CreateListingSaveDraftButton';
import './CreateListingUnsavedChangesModal.scss';

const allowNavigation = (newLocation: Location) =>
    (newLocation.pathname.startsWith(RoutePath.CREATE_LISTING_WIZARD) && !newLocation.state?.fromNavbar) ||
    newLocation.pathname === RoutePath.CREATE_LISTING_SUMMARY ||
    newLocation.pathname === RoutePath.CREATE_LISTING_CHOOSE_PICKUP_ADDRESS ||
    newLocation.pathname === RoutePath.CREATE_LISTING_ADD_PICKUP_ADDRESS;

export const CreateListingUnsavedChangesModal = () => {
    const { t } = useTranslation('translation');
    const createListingContext = useCreateListingContext();
    const listingSellerDraftCollectionData = useListingSellerDraftCollectionData();
    const [modal, unblockAndRetry] = useBlocker(createListingContext.unsavedChanges, allowNavigation);
    const [error, setError] = useState<boolean>(false);

    const { areFeaturesSupported } = useFeatureFlag();

    const hasPackageFeatureEnabled = areFeaturesSupported([Feature.PACKAGES, Feature.PACKAGES_DRAFT]);

    const isPackage = createListingContext.materialStepData.product === Product.PACKAGE;

    const isShowDraftButton = !isPackage || hasPackageFeatureEnabled;

    listingSellerDraftCollectionData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setError(true);
        },
        true
    );

    return (
        <CreateUnsavedChangesModal
            headline={t('application.createListingUnsavedChangesModal.headline')}
            error={error}
            modal={modal}
            setError={setError}
            unblockAndRetry={unblockAndRetry}
        >
            {isShowDraftButton && (
                <CreateListingSaveDraftButton
                    buttonComponent={ButtonPrimary}
                    onSaveSuccess={unblockAndRetry}
                    isPackage={isPackage}
                />
            )}
        </CreateUnsavedChangesModal>
    );
};
