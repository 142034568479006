import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product, formatInputToNumber } from '@steelbuy/ts-shared';
import {
    FormItem,
    InputTextfieldUnit,
    InputTextfieldCombination,
    ButtonGhostSystemOnLightM,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { useNumberValidator, useRequiredFieldValidator } from '../validation/ValidationHooks';
import './MaterialDimensionSearchSelection.scss';

const ProductsWithLength = [Product.PLATE, Product.SHEET, Product.TREAD];

export type MaterialDimensionSearchSpecification = {
    thickness?: number;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    length?: number;
    minLength?: number;
    maxLength?: number;
};

export type MaterialDimensionSearchInput = {
    thickness?: string;
    width?: string;
    minWidth?: string;
    maxWidth?: string;
    length?: string;
    minLength?: string;
    maxLength?: string;
};

interface MaterialDimensionSearchSelectionProps {
    product: Product;
    initialDimensions: MaterialDimensionSearchInput;
    setDimensions: (dimensions: MaterialDimensionSearchSpecification) => void;
    widthRequired?: boolean;
    thicknessRequired?: boolean;
    forceValidation?: boolean;
    lengthRequired?: boolean;
    setIsWidthRange?: (isRange: boolean) => void;
    setIsLengthRange?: (isRange: boolean) => void;
}

export const MaterialDimensionSearchSelection = (props: MaterialDimensionSearchSelectionProps) => {
    const { t } = useTranslation(['domainModel']);
    const {
        thicknessRequired,
        widthRequired,
        lengthRequired,
        forceValidation,
        initialDimensions,
        setDimensions,
        product,
        setIsWidthRange,
        setIsLengthRange,
    } = props;
    const [showWidthRanges, setShowWidthRanges] = useState<boolean>(initialDimensions.minWidth !== undefined);
    const [showLengthRanges, setShowLengthRanges] = useState<boolean>(initialDimensions.minLength !== undefined);
    const requiredFieldValidator = useRequiredFieldValidator();
    const numberValidator = useNumberValidator();
    const isLengthProduct = ProductsWithLength.includes(product);

    const [thickness, setThickness] = useState<string | undefined>(initialDimensions.thickness || '');
    const [width, setWidth] = useState<string | undefined>(initialDimensions.width || '');
    const [length, setLength] = useState<string | undefined>(initialDimensions.length || '');
    const [minWidth, setMinWidth] = useState<string | undefined>(initialDimensions.minWidth || '');
    const [maxWidth, setMaxWidth] = useState<string | undefined>(initialDimensions.maxWidth || '');
    const [minLength, setMinLength] = useState<string | undefined>(initialDimensions.minLength || '');
    const [maxLength, setMaxLength] = useState<string | undefined>(initialDimensions.maxLength || '');

    const setDimension = (
        value: string,
        setter: Dispatch<SetStateAction<string | undefined>>,
        field: keyof MaterialDimensionSearchSpecification
    ) => {
        setter(value);

        const valueAsFloat = formatInputToNumber(value);
        if (value && !Number.isNaN(valueAsFloat)) {
            setDimensions({ [field]: valueAsFloat });
        } else {
            setDimensions({ [field]: undefined });
        }
    };

    useEffect(() => {
        if (length !== undefined && !isLengthProduct) {
            setDimensions({ length: undefined });
        }
    }, [length, isLengthProduct]);

    const updateWidthRange = (isRange: boolean) => {
        setShowWidthRanges(isRange);
        setIsWidthRange?.(isRange);
    };

    const updateLengthRange = (isRange: boolean) => {
        setShowLengthRanges(isRange);
        setIsLengthRange?.(isRange);
    };

    return (
        <FormItem header={t('domainModel:material.dimensions.name')}>
            <Notification
                level={NotificationLevel.INFO}
                message={t('domainModel:material.dimensions.dimensionsNotification')}
            />
            <InputTextfieldUnit
                unit={t('domainModel:material.dimensions.units.millimeters')}
                label={t('domainModel:material.dimensions.thickness')}
                name="thickness"
                value={thickness ?? ''}
                onChange={(value) => {
                    setDimension(value, setThickness, 'thickness');
                }}
                validators={thicknessRequired ? [requiredFieldValidator, numberValidator] : [numberValidator]}
                forceValidation={forceValidation}
                testId="thickness-input"
                isMandatory={thicknessRequired}
            />
            <InputTextfieldCombination label={t('domainModel:material.dimensions.width')} isMandatory={widthRequired}>
                {!showWidthRanges && (
                    <>
                        <InputTextfieldUnit
                            unit={t('domainModel:material.dimensions.units.millimeters')}
                            name="width"
                            value={width ?? ''}
                            onChange={(value) => {
                                setDimension(value, setWidth, 'width');
                            }}
                            validators={widthRequired ? [requiredFieldValidator, numberValidator] : [numberValidator]}
                            forceValidation={forceValidation}
                            testId="width-input"
                        />
                        <div>
                            <ButtonGhostSystemOnLightM
                                label={t('domainModel:material.dimensions.addRange')}
                                onClick={() => {
                                    setDimensions({ width: undefined });
                                    updateWidthRange(true);
                                }}
                            />
                        </div>
                    </>
                )}
                {showWidthRanges && (
                    <>
                        <InputTextfieldUnit
                            unit={t('domainModel:material.dimensions.units.millimeters')}
                            label={t('domainModel:material.dimensions.min')}
                            name="minWidth"
                            value={minWidth ?? ''}
                            onChange={(value) => {
                                setDimension(value, setMinWidth, 'minWidth');
                            }}
                            validators={[requiredFieldValidator, numberValidator]}
                            forceValidation
                            testId="min-width-input"
                        />

                        <InputTextfieldUnit
                            unit={t('domainModel:material.dimensions.units.millimeters')}
                            label={t('domainModel:material.dimensions.max')}
                            name="maxWidth"
                            value={maxWidth ?? ''}
                            onChange={(value) => {
                                setDimension(value, setMaxWidth, 'maxWidth');
                            }}
                            validators={[requiredFieldValidator, numberValidator]}
                            forceValidation
                            required={widthRequired}
                            testId="max-width-input"
                        />
                        <ButtonGhostSystemOnLightM
                            label={t('domainModel:material.dimensions.deleteRange')}
                            onClick={() => {
                                updateWidthRange(false);
                                setDimensions({
                                    minWidth: undefined,
                                    maxWidth: undefined,
                                });
                            }}
                            className="delete-range-button"
                        />
                    </>
                )}
            </InputTextfieldCombination>
            {showWidthRanges && (
                <Notification
                    level={NotificationLevel.WARNING}
                    message={t('domainModel:material.dimensions.rangeNotification')}
                />
            )}
            {isLengthProduct ? (
                <>
                    <InputTextfieldCombination
                        label={t('domainModel:material.dimensions.length')}
                        isMandatory={lengthRequired}
                    >
                        {!showLengthRanges && (
                            <>
                                <InputTextfieldUnit
                                    unit={t('domainModel:material.dimensions.units.millimeters')}
                                    name="length"
                                    value={length ?? ''}
                                    onChange={(value) => {
                                        setDimension(value, setLength, 'length');
                                    }}
                                    validators={
                                        lengthRequired ? [requiredFieldValidator, numberValidator] : [numberValidator]
                                    }
                                    forceValidation={forceValidation}
                                    testId="length-input"
                                />
                                <div>
                                    <ButtonGhostSystemOnLightM
                                        label={t('domainModel:material.dimensions.addRange')}
                                        onClick={() => {
                                            setDimensions({ length: undefined });
                                            updateLengthRange(true);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {showLengthRanges && (
                            <>
                                <InputTextfieldUnit
                                    unit={t('domainModel:material.dimensions.units.millimeters')}
                                    label={t('domainModel:material.dimensions.min')}
                                    name="minLength"
                                    value={minLength ?? ''}
                                    onChange={(value) => {
                                        setDimension(value, setMinLength, 'minLength');
                                    }}
                                    validators={[requiredFieldValidator, numberValidator]}
                                    forceValidation
                                    testId="min-length-input"
                                />
                                <InputTextfieldUnit
                                    unit={t('domainModel:material.dimensions.units.millimeters')}
                                    label={t('domainModel:material.dimensions.max')}
                                    name="maxLength"
                                    value={maxLength ?? ''}
                                    onChange={(value) => {
                                        setDimension(value, setMaxLength, 'maxLength');
                                    }}
                                    validators={[requiredFieldValidator, numberValidator]}
                                    forceValidation
                                    testId="max-length-input"
                                />
                                <ButtonGhostSystemOnLightM
                                    label={t('domainModel:material.dimensions.deleteRange')}
                                    onClick={() => {
                                        setDimensions({
                                            minLength: undefined,
                                            maxLength: undefined,
                                        });
                                        updateLengthRange(false);
                                    }}
                                    className="delete-range-button"
                                />
                            </>
                        )}
                    </InputTextfieldCombination>
                    {showLengthRanges && (
                        <Notification
                            level={NotificationLevel.WARNING}
                            message={t('domainModel:material.dimensions.rangeNotification')}
                        />
                    )}
                </>
            ) : null}
        </FormItem>
    );
};
