import { useTranslation } from 'react-i18next';
import { CartProductType } from '@steelbuy/data-access';
import {
    formatCurrency,
    CartEntryModel,
    AnyMaterialModel,
    getPricingUnit,
    MaterialProperties,
    Product,
    formatNumber,
    isListingCartEntry,
} from '@steelbuy/ts-shared';

import { CartSummaryHeader } from './cart-summary-header/CartSummaryHeader';

import { useMaterialProperties } from '../material/Material';

import './CartSummary.scss';

export type CartSummaryProps = {
    cartData: CartEntryModel;
};

export const CartSummary = ({ cartData }: CartSummaryProps) => {
    const { t } = useTranslation(['domainModel', 'uiDomain']);

    const isPackage = cartData.productType === CartProductType.PACKAGE;
    const material = cartData.product.material as AnyMaterialModel;

    const materialTypeProperty = isPackage ? cartData.product.material : cartData.product.material.materialType;

    const materialProperties = useMaterialProperties(isPackage ? cartData.product : material, ['materialType']);

    const materialType = t(`material.materialType.value.${materialTypeProperty}`);

    const tolerance = material.tolerance ? t(`material.tolerance.value.${material.tolerance}`) : '';

    const productType = isPackage
        ? t(`domainModel:material.product.value.${Product.PACKAGE}`)
        : t(`domainModel:material.product.value.${cartData.product.material.product}`);

    const pricingUnit = getPricingUnit((isPackage ? { materialType: material } : material) as MaterialProperties);
    const weight =
        cartData.product.weight && cartData.product.tradeUnit
            ? t(`material.tradeUnit.value.${cartData.product.tradeUnit}.quantity`, {
                  count: cartData.product.weight,
                  formatted: formatNumber(cartData.product.weight),
              })
            : '';

    const pricePerUnitLabel = t('uiDomain:cartModal.pricePerUnit', {
        pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
    });

    const pricePerUnit = formatCurrency(
        isListingCartEntry(cartData)
            ? cartData.product.offerPricing?.priceIncludingDelivery.value ??
                  cartData.product.pricing.priceIncludingDelivery?.value
            : cartData.product.pricing.priceIncludingDelivery?.value,
        cartData.product.pricing.currencyCode
    );

    return (
        <div className="cart-summary">
            <div className="cart-summary__header-wraper">
                <CartSummaryHeader
                    symbol={isPackage ? cartData.productType.toLowerCase() : material.product.toLowerCase()}
                    firstLine={materialProperties.map((property) => property.label).join(', ')}
                    secondLine={`${productType}, ${materialType}`}
                    thirdLine={tolerance}
                    fourthLine={weight}
                />
                <div className="cart-summary__header-wraper__pricing">
                    <span className="cart-summary__header-wraper__pricing__value">{pricePerUnit}</span>
                    <span className="cart-summary__header-wraper__pricing__label">{pricePerUnitLabel}</span>
                </div>
            </div>
        </div>
    );
};
