import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionStatus, useUserCollectionData } from '@steelbuy/data-provider';
import { Mutable, UserModel, UserRole } from '@steelbuy/ts-shared';

import { PermissionInformation } from '@steelbuy/ui-domain';
import {
    ButtonPrimary,
    ButtonSecondary,
    FormActionbar,
    FormItem,
    InputCheckbox,
    InputCheckboxGroup,
    InputCheckboxGroupAlign,
    InputTextfield,
    InputToggleButton,
    LoadingStatus,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';

import './UserForm.scss';
import { ROUTE_ACCOUNT_USER_MANAGEMENT } from '../account/Account.enum';

export type UserFormProps = {
    onPrimary?: (model: Mutable<UserModel>) => void;
    userData?: UserModel;
    isUserIdSame?: boolean;
    saveLabelText: string;
    isEmailDisabled?: boolean;
};

export const UserForm = ({ onPrimary, userData, isUserIdSame, saveLabelText, isEmailDisabled }: UserFormProps) => {
    const [name, setName] = useState(userData ? userData.name : '');
    const [email, setEmail] = useState(userData ? userData.email : '');
    const [roles, setRoles] = useState(userData ? userData.roles : []);
    const [error, setError] = useState('');
    const [activated, setActivated] = useState(userData?.activated);

    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const navigate = useNavigate();

    const userCollectionData = useUserCollectionData();
    const actionStatus = userCollectionData.queryActionStatus();
    const actionError = userCollectionData.queryActionError();

    userCollectionData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            navigate(ROUTE_ACCOUNT_USER_MANAGEMENT);
        },
        false
    );

    userCollectionData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            navigate(ROUTE_ACCOUNT_USER_MANAGEMENT);
        },
        false
    );

    userCollectionData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            if (
                actionError?.message === 'Invalid request with message Email must be from same domain as organisation'
            ) {
                setError(t('translation:application.userForm.errors.invalidDomain'));
            } else {
                setError(t('translation:application.userForm.errors.other'));
            }
        },
        true
    );

    useEffect(() => {
        setActivated(userData?.activated);
    }, [userData]);

    const saveButtonDisabled = () => {
        const rolesAreSame =
            roles.length === userData?.roles.length &&
            roles.every((element: UserRole) => userData?.roles.includes(element));
        if (!name || !email) {
            return true;
        }

        if (
            userData &&
            name === userData.name &&
            email === userData.email &&
            rolesAreSame &&
            activated === userData?.activated
        ) {
            return true;
        }
        return false;
    };

    const isPending = [ActionStatus.CREATE_PENDING, ActionStatus.MUTATE_PENDING].includes(actionStatus);

    const handleRoleChange = (checked: boolean, value: UserRole) => {
        if (!checked) {
            const filteredRoles = roles?.filter((role: UserRole) => role !== value);
            setRoles(filteredRoles);
        } else {
            const newRoles = [...roles, value];
            setRoles(newRoles);
        }
    };

    const userRoleChecker = (role: UserRole) => !!roles?.includes(role);
    return (
        <FormLayout>
            <div className="user-form">
                <form>
                    {error && <Notification level={NotificationLevel.ERROR} message={error} />}
                    <FormItem>
                        <InputTextfield
                            name="name"
                            value={name}
                            onChange={(value) => {
                                setName(value);
                            }}
                            disabled={activated === false}
                            autoComplete="off"
                            autoCorrect="off"
                            label={t('translation:application.userForm.nameFieldLabel')}
                        />

                        <InputTextfield
                            name="email"
                            value={email}
                            onChange={(value) => {
                                setEmail(value);
                            }}
                            autoComplete="off"
                            autoCorrect="off"
                            label={t('translation:application.userForm.emailFieldLabel')}
                            disabled={isEmailDisabled || activated === false}
                        />

                        {userData && (
                            <InputToggleButton
                                value={t('translation:application.userForm.activateToggleLabel')}
                                checked={!!activated}
                                onChange={() => setActivated(!activated)}
                                disabled={isUserIdSame}
                            />
                        )}

                        <InputCheckboxGroup
                            label={t('translation:application.userForm.permissionsFieldLabel')}
                            align={InputCheckboxGroupAlign.ALIGN_VERTICAL}
                        >
                            <InputCheckbox
                                disabled // BASIC role always disabled
                                checked
                                value={t('domainModel:user.permissions.BASIC')}
                            >
                                <PermissionInformation disabled />
                            </InputCheckbox>
                            <InputCheckbox
                                value={t('domainModel:user.permissions.BUYER')}
                                checked={userRoleChecker(UserRole.BUYER)}
                                disabled={activated === false}
                                onChange={(checked) => {
                                    handleRoleChange(checked, UserRole.BUYER);
                                }}
                            >
                                <PermissionInformation disabled={activated === false} role={UserRole.BUYER} />
                            </InputCheckbox>
                            <InputCheckbox
                                value={t(`domainModel:user.permissions.${UserRole.SELLER}`)}
                                checked={userRoleChecker(UserRole.SELLER)}
                                disabled={activated === false}
                                onChange={(checked) => {
                                    handleRoleChange(checked, UserRole.SELLER);
                                }}
                            >
                                <PermissionInformation disabled={activated === false} role={UserRole.SELLER} />
                            </InputCheckbox>
                            <InputCheckbox
                                disabled={isUserIdSame || activated === false}
                                value={t(`domainModel:user.permissions.${UserRole.MANAGER}`)}
                                checked={userRoleChecker(UserRole.MANAGER)}
                                onChange={(checked) => {
                                    handleRoleChange(checked, UserRole.MANAGER);
                                }}
                            >
                                <PermissionInformation disabled={activated === false} role={UserRole.MANAGER} />
                            </InputCheckbox>
                        </InputCheckboxGroup>
                    </FormItem>

                    <div className="user-form__notification-and-action-bar">
                        <FormActionbar>
                            <ButtonSecondary // CANCEL BUTTON
                                label={t('uiDomain:common.cancel')}
                                onClick={() => navigate(-1)}
                            />

                            <ButtonPrimary // SAVE / CREATE USER BUTTON
                                disabled={saveButtonDisabled()}
                                label={saveLabelText}
                                onClick={() =>
                                    onPrimary?.({
                                        name,
                                        email,
                                        roles,
                                        activated: !!activated,
                                    } as UserModel)
                                }
                                loadingStatus={isPending ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                            />
                        </FormActionbar>
                    </div>
                </form>
            </div>
        </FormLayout>
    );
};
