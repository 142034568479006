import { useTranslation } from 'react-i18next';
import {
    formatCurrency,
    OrderBuyerModel,
    getPricingUnit,
    isListingOrderModel,
    formatNumber,
} from '@steelbuy/ts-shared';
import { Card, CardContentProductViewPrice, CardLayout } from '@steelbuy/ui-primitive';

import { useNumberOfItemsLabel } from '../number-of-items-label/NumberOfItemsLabel';

type ListingBuyerOrderDetailsPriceProps = {
    orderModel: OrderBuyerModel;
};

export const ListingBuyerOrderDetailsPrice = ({ orderModel }: ListingBuyerOrderDetailsPriceProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const numberOfItemsLabel = useNumberOfItemsLabel(
        isListingOrderModel(orderModel) ? orderModel.listing.material.product : undefined
    );

    const pricingUnit = getPricingUnit(
        isListingOrderModel(orderModel) ? orderModel.listing.material : { materialType: orderModel.packaging.material }
    );
    const listing = isListingOrderModel(orderModel) ? orderModel.listing : orderModel.packaging;

    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentProductViewPrice
                    header={t('uiDomain:listingBuyerDetailsPrice.cardTitle')}
                    pricePerUnit={formatCurrency(
                        listing.pricing.priceIncludingDelivery.value,
                        listing.pricing.currencyCode
                    )}
                    pricePerUnitLabel={t('uiDomain:listingBuyerDetailsPrice.pricePerUnit', {
                        pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
                    })}
                    weight={t(`domainModel:material.tradeUnit.value.${listing.tradeUnit}.quantity`, {
                        count: listing.weight,
                        formatted: formatNumber(listing.weight),
                    })}
                    numberOfItems={
                        numberOfItemsLabel && isListingOrderModel(orderModel) && orderModel.listing.numberOfItems
                            ? {
                                  label: numberOfItemsLabel,
                                  amount: orderModel.listing.numberOfItems,
                              }
                            : undefined
                    }
                    totalExVat={formatCurrency(listing.pricing.totalExcludingVat.value, listing.pricing.currencyCode)}
                />
            </CardLayout>
        </Card>
    );
};
