import { useTranslation } from 'react-i18next';
import { formatCurrency, PricingUnit, getCurrencyFromRegion, getRegion } from '@steelbuy/ts-shared';
import { MarketingBannerDeterminePrice } from '@steelbuy/ui-primitive';

import './TargetPriceBanner.scss';

type TargetPriceBannerProps = {
    targetPrice: number;
    pricingUnit: PricingUnit;
};

export const TargetPriceBanner = ({ targetPrice, pricingUnit }: TargetPriceBannerProps) => {
    const { t } = useTranslation(['translation', 'domainModel']);
    return (
        <MarketingBannerDeterminePrice header={t('translation:application.createListing.targetPriceHeader')}>
            <section>
                <p className="target-price-value">{`${formatCurrency(
                    targetPrice,
                    getCurrencyFromRegion(getRegion())
                )}`}</p>
                <p className="target-price-description">
                    {t('domainModel:material.perPricingUnit', {
                        pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
                    })}
                </p>
            </section>
        </MarketingBannerDeterminePrice>
    );
};
