import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    ButtonGhostSystemOnLightM,
    ButtonPrimary,
    ButtonTertiaryOnLightM,
    FormActionbar,
    MarketingBannerContact,
    NotificationIllustrated,
    NotificationIllustratedAlignment,
    NotificationIllustratedItem,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { useCreateListingContext } from '../create-listing/CreateListingContext';
import { PageHeader } from '../page-header/PageHeader';
import './CreateListingSuccess.scss';

export const CreateListingSuccess = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const createListingContext = useCreateListingContext();
    const resetListingOnUnmount = useRef(true);

    const handleCreateSame = () => {
        resetListingOnUnmount.current = false;
        createListingContext.createSame();
        navigate(RoutePath.CREATE_LISTING_WIZARD);
    };

    const handleCreateNew = () => {
        createListingContext.reset();
        navigate(RoutePath.CREATE_LISTING_WIZARD);
    };

    useEffect(() => {
        scrollMainLayout(0, 0);
    }, []);

    useEffect(
        () => () => {
            // reset listing data when unmounted unless we are creating same listing
            if (resetListingOnUnmount.current) {
                createListingContext.reset();
            }
        },
        []
    );

    return (
        <>
            <PageHeader pageTitle={t('application.createListingSuccess.header')} />
            <div className="create-listing-success">
                <FormLayout>
                    <NotificationIllustrated
                        alignment={NotificationIllustratedAlignment.VERTICAL}
                        imgSrc="/assets/lib-ui-primitive/images/ListingReview-Success.svg"
                        header={t('application.createListingSuccess.notificationMessage')}
                        helperText=""
                    >
                        <NotificationIllustratedItem text={t('application.createListingSuccess.nextStepsHint')} />
                        <NotificationIllustratedItem
                            text={t('application.createListingSuccess.nextStepReview')}
                            isChecked
                        />
                        <NotificationIllustratedItem
                            text={t('application.createListingSuccess.nextStepNotification')}
                            isChecked
                        />
                    </NotificationIllustrated>
                    <MarketingBannerContact
                        header={t('application.createListingSuccess.marketingBannerHeader')}
                        text={t('application.createListingSuccess.marketingBannerText')}
                    >
                        <a href="mailto:support@steel-buy.com">
                            {' '}
                            {t('application.createListingSuccess.marketingBannerEmail')}
                        </a>
                    </MarketingBannerContact>
                    <FormActionbar>
                        <ButtonGhostSystemOnLightM
                            label={t('application.createListingSuccess.buttonLabelMyListings')}
                            onClick={() => navigate(RoutePath.MY_LISTINGS)}
                        />
                        <ButtonTertiaryOnLightM
                            label={t('application.createListingSuccess.buttonLabelCreateSame')}
                            onClick={handleCreateSame}
                        />
                        <ButtonPrimary
                            label={t('application.createListingSuccess.buttonLabelCreateNew')}
                            onClick={handleCreateNew}
                        />
                    </FormActionbar>
                </FormLayout>
            </div>
        </>
    );
};
