import { useMemo } from 'react';
import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select, InputType } from '@steelbuy/form';
import { formatDateOnlyMonthAndYear } from '@steelbuy/ts-shared';
import { FormItem } from '@steelbuy/ui-primitive';

interface RFQDeliverySelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    name: TName;
    control: Control<TFieldValues>;
    error?: FieldError;
}

const MONTHS_PER_YEAR = 12;
const MONTHS_OFFSET = 2;

const calculateDeliveryOptions = (currentDate: Date) => {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

    let startingMonth = currentMonth + MONTHS_OFFSET;
    let year = currentYear;

    // Adjust starting month and year if it exceeds 12
    if (startingMonth > MONTHS_PER_YEAR) {
        startingMonth -= MONTHS_PER_YEAR;
        year += 1;
    }

    let month = startingMonth;
    let i = 0;

    const newOptions = [];
    while (i < MONTHS_PER_YEAR) {
        const date = new Date(year, month - 1);
        const optionText = formatDateOnlyMonthAndYear(date);

        newOptions.push({ value: `${date.getMonth() + 1}_${date.getFullYear()}`, label: optionText });
        month = month === MONTHS_PER_YEAR ? 1 : month + 1; // Increment month, resetting to 1 for January if it's December
        if (month === 1) {
            year += 1;
        }
        i += 1;
    }
    return newOptions;
};

export const RFQDeliverySelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    control,
    error,
}: RFQDeliverySelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['domainModel', 'uiDomain', 'translation']);
    const currentDate = new Date();
    const options = useMemo(() => calculateDeliveryOptions(currentDate), [currentDate.getMonth()]);

    return (
        <FormItem
            header={t('translation:application.createRFQ.deliveryMonth.header')}
            description={t('translation:application.createRFQ.deliveryMonth.description')}
            isMandatory
        >
            <Select
                options={options}
                placeholder={t(`translation:application.createRFQ.selectPlaceholder`)}
                name={name}
                control={control}
                rules={{ required: t(`translation:application.createRFQ.fieldRequired`) }}
                error={error?.message}
                type={InputType.BLOCK}
            />
        </FormItem>
    );
};
