import i18next from 'i18next';
import { PropsWithChildren, useEffect } from 'react';
import { Routes, Route /* useLocation */ } from 'react-router-dom';
import { getUserLanguage } from '@steelbuy/ts-shared';
import { RoutePath } from './Routes';
import { constants } from '../constants';
import { ChallengeRespondView } from '../views/ChallengeRespondView';
import { ForgotPasswordView } from '../views/ForgotPasswordView';
import { LoginView } from '../views/LoginView';
import { ResetPasswordView } from '../views/ResetPasswordView';

export const LoginRouter = ({ children }: PropsWithChildren) => {
    useEffect(() => {
        if (constants.internationalisation === 'true') {
            const { languages } = navigator;
            if (i18next.language !== getUserLanguage(languages)) {
                i18next.changeLanguage(getUserLanguage(languages));
            }
        }
    }, []);
    return (
        <Routes>
            <Route path={RoutePath.LOGIN} element={<LoginView />} />
            <Route path={RoutePath.CHALLENGE_RESPOND} element={<ChallengeRespondView />} />
            <Route path={RoutePath.FORGOT_PASSWORD} element={<ForgotPasswordView />} />
            <Route path={RoutePath.RESET_PASSWORD} element={<ResetPasswordView />} />
            <Route path="*" element={children} />
        </Routes>
    );
};
