import axios from '../../axios';

interface GetCsrfTokenResponse {
    csrfEmailToken: string;
}

export const getCsrfToken = async () => {
    const { data } = await axios.get('api/universal/users/email-csrf-token');
    return data as GetCsrfTokenResponse;
};
