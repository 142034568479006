import { useTranslation } from 'react-i18next';
import { formatDateOnlyMonthAndYear } from '@steelbuy/ts-shared';
import { CardContentLabelValues } from '@steelbuy/ui-primitive';

const RADIX = 10;

export const CardContentDeliveryMonth = ({ delivery }: { delivery: string }) => {
    const { t } = useTranslation('translation');

    const [month, year] = delivery.split('_');
    const date = new Date(parseInt(year, RADIX), parseInt(month, RADIX) - 1);
    formatDateOnlyMonthAndYear(date);

    const labelValues = [
        {
            label: t('application.createRFQ.deliveryMonth.subHeader'),
            value: formatDateOnlyMonthAndYear(date),
        },
    ];

    return <CardContentLabelValues title={t('application.createRFQ.deliveryMonth.header')} labelValues={labelValues} />;
};
