import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserSelfDetails } from '@steelbuy/data-access';
import {
    ActionStatus,
    FetchStatus,
    useAddressCollectionData,
    useOrganisationEntityData,
} from '@steelbuy/data-provider';
import { NotFoundError } from '@steelbuy/error';
import {
    formatCurrency,
    ModelPrimaryKey,
    MonetaryAmount,
    isFinancialDetailsWithBalanceModel,
    UserRole,
} from '@steelbuy/ts-shared';
import { Address } from '@steelbuy/ui-domain';
import {
    ButtonGhostSystemOnLightM,
    ButtonTertiaryOnLightM,
    Card,
    CardContentAccountAttribute,
    CardContentAttributesItem,
    CardLayout,
    FormActionbar,
    HeadingCardsSection,
    IconIdentifier,
    LoadingStatus,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';

import './Organisation.scss';

export const Organisation = () => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const navigate = useNavigate();
    const { data: userModel } = useUserSelfDetails();
    const organisationEntityData = useOrganisationEntityData();
    const { warehouseAddressCollectionData, invoiceAddressCollectionData } = useAddressCollectionData();

    const [organisationUpdated, setOrganisationUpdated] = useState<boolean>(false);
    const [addressAdded, setAddressAdded] = useState<boolean>(false);
    const [addressUpdated, setAddressUpdated] = useState<boolean>(false);
    const [addressDeleted, setAddressDeleted] = useState<boolean>(false);

    const organisation = organisationEntityData.query().getOrThrow(new NotFoundError());
    const warehouseAddresses = warehouseAddressCollectionData.query();
    const hasNext = warehouseAddressCollectionData.hasNextPage();
    const invoiceAddresses = invoiceAddressCollectionData.query();
    const invoiceAddress = invoiceAddresses.length > 0 ? invoiceAddresses[0] : undefined;
    const hasManagerRole = userModel?.roles.includes(UserRole.MANAGER) ?? false;
    const fetchStatus = warehouseAddressCollectionData.queryFetchStatus();

    organisationEntityData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            setOrganisationUpdated(true);
        },
        true
    );

    invoiceAddressCollectionData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            setAddressUpdated(true);
        },
        true
    );

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            setAddressAdded(true);
        },
        true
    );

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            setAddressUpdated(true);
        },
        true
    );

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.DELETE_SUCCESS],
        () => {
            setAddressDeleted(true);
        },
        true
    );

    const renderOrganisationSaved = () => {
        if (!organisationUpdated) {
            return null;
        }

        return (
            <Notification
                level={NotificationLevel.SUCCESS}
                message={t('translation:application.organisation.organisationSavedNotificationText')}
                onClose={() => setOrganisationUpdated(false)}
            />
        );
    };

    const renderAddressAdded = () => {
        if (!addressAdded) {
            return null;
        }

        return (
            <Notification
                level={NotificationLevel.SUCCESS}
                message={t('translation:application.organisation.addressAddedNotificationText')}
                onClose={() => setAddressAdded(false)}
                stayOpen
                closeButton
            />
        );
    };

    const renderAddressUpdated = () => {
        if (!addressUpdated) {
            return null;
        }

        return (
            <Notification
                level={NotificationLevel.SUCCESS}
                message={t('translation:application.organisation.addressUpdatedNotificationText')}
                onClose={() => setAddressUpdated(false)}
            />
        );
    };

    const renderAddressDeleted = () => {
        if (!addressDeleted) {
            return null;
        }

        return (
            <Notification
                level={NotificationLevel.SUCCESS}
                message={t('translation:application.organisation.addressDeletedNotificationText')}
                onClose={() => setAddressDeleted(false)}
            />
        );
    };

    const handleEditAddress = (addressId: ModelPrimaryKey) => {
        navigate(createRouteUrl(RoutePath.ACCOUNT_EDIT_ADDRESS, ['addressId', addressId]));
    };

    const renderFinancialDetails = () => {
        const { financialDetails } = organisation;

        if (!hasManagerRole || !isFinancialDetailsWithBalanceModel(financialDetails)) {
            return null;
        }

        const remainingCredit: MonetaryAmount = {
            discriminator: 'MonetaryAmount',
            value: financialDetails.erpCreditLimit.value - financialDetails.erpBalance.value,
        };

        return (
            <HeadingCardsSection headline={t('translation:application.organisation.paymentSectionHeading')}>
                <Card isClickable={false}>
                    <CardLayout>
                        <CardContentAccountAttribute
                            header={t('translation:application.organisation.creditCardHeader')}
                        >
                            <CardContentAttributesItem
                                label={t('translation:application.organisation.creditLineTotalLabel')}
                                value={formatCurrency(
                                    financialDetails.erpCreditLimit.value,
                                    financialDetails.currencyCode
                                )}
                            />
                            <CardContentAttributesItem
                                label={t('translation:application.organisation.creditLineBalanceLabel')}
                                value={formatCurrency(remainingCredit.value, financialDetails.currencyCode)}
                            />
                        </CardContentAccountAttribute>
                    </CardLayout>
                </Card>

                <Card isClickable={false}>
                    <CardLayout>
                        <CardContentAccountAttribute
                            header={t('translation:application.organisation.paymentTermsHeader')}
                        >
                            <CardContentAttributesItem value={t('domainModel:organisation.paymentTerms.default')} />
                        </CardContentAccountAttribute>
                    </CardLayout>
                </Card>
            </HeadingCardsSection>
        );
    };

    return (
        <DetailsLayout className="organisation">
            {renderOrganisationSaved()}
            {renderAddressAdded()}
            {renderAddressUpdated()}
            {renderAddressDeleted()}
            <HeadingCardsSection>
                <Card isClickable={false}>
                    <CardLayout>
                        <CardContentAccountAttribute
                            header={t('translation:application.organisation.organisationCardHeader')}
                        >
                            <CardContentAttributesItem
                                label={t('translation:application.organisation.organisationCardNameLabel')}
                                value={organisation.name}
                            />
                        </CardContentAccountAttribute>
                    </CardLayout>
                </Card>

                <Card isClickable={false}>
                    <CardLayout>
                        <CardContentAccountAttribute
                            header={t('translation:application.organisation.invoiceAddressCardHeader')}
                        >
                            <Address addressModel={invoiceAddress} phoneNumber={invoiceAddress?.phone} />
                        </CardContentAccountAttribute>
                    </CardLayout>
                </Card>

                <Card isClickable={false}>
                    <CardLayout>
                        <CardContentAccountAttribute
                            header={t('translation:application.organisation.purchaseContactCardHeader')}
                            buttonLabel={hasManagerRole ? t('uiDomain:common.edit') : undefined}
                            onButtonClick={() => navigate(RoutePath.ACCOUNT_EDIT_PURCHASE_CONTACT)}
                        >
                            <CardContentAttributesItem
                                label={t('translation:application.organisation.purchaseContactNameLabel')}
                                value={organisation.purchaseContactName}
                            />
                            <CardContentAttributesItem
                                label={t('translation:application.organisation.purchaseContactEmailLabel')}
                                value={organisation.purchaseContactEmail}
                            />
                        </CardContentAccountAttribute>
                    </CardLayout>
                </Card>
            </HeadingCardsSection>
            {renderFinancialDetails()}

            <HeadingCardsSection headline={t('translation:application.organisation.warehouseAddressesSectionHeading')}>
                {warehouseAddresses.map((address, index) => (
                    <Card isClickable={false} key={address.id}>
                        <CardLayout>
                            <CardContentAccountAttribute
                                header={t('translation:application.organisation.warehouseAddressHeader', {
                                    number: (index + 1).toString(),
                                })}
                                tagLabel={
                                    address.primary
                                        ? t('translation:application.organisation.primaryAddressTag')
                                        : undefined
                                }
                                buttonLabel={hasManagerRole ? t('uiDomain:common.edit') : undefined}
                                onButtonClick={() => handleEditAddress(address.id)}
                            >
                                <Address addressModel={address} phoneNumber={address.phone} />
                            </CardContentAccountAttribute>
                        </CardLayout>
                    </Card>
                ))}
                <FormActionbar>
                    {hasNext && (
                        <ButtonGhostSystemOnLightM
                            label={t('uiDomain:commonList.loadMore')}
                            onClick={() => warehouseAddressCollectionData.fetchNext()}
                            loadingStatus={
                                fetchStatus === FetchStatus.PAGING_PENDING ? LoadingStatus.PENDING : LoadingStatus.IDLE
                            }
                        />
                    )}
                    {hasManagerRole ? (
                        <ButtonTertiaryOnLightM
                            icon={IconIdentifier.PLUS}
                            label={t('translation:application.organisation.createNewButtonLabel')}
                            onClick={() => navigate(RoutePath.ACCOUNT_ADD_ADDRESS)}
                        />
                    ) : null}
                </FormActionbar>
            </HeadingCardsSection>
        </DetailsLayout>
    );
};
