import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useFeatureFlag } from '@steelbuy/data-access';
import {
    Feature,
    TradeUnitCalculation,
    getPricingUnit,
    getTradeUnit,
    getTradeUnitCalculation,
    MonetaryAmount,
    formatNumberForInput,
    getCurrencyFromRegion,
    getRegion,
} from '@steelbuy/ts-shared';

import {
    AutoRenewalCheckbox,
    ExpiryTimeSelection,
    MaterialWeightInput,
    NumberOfItemsInput,
    MaterialPriceInput,
} from '@steelbuy/ui-domain';
import { Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { StepProps } from './StepProps';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';
import { TargetPriceBanner } from '../components/target-price-banner/TargetPriceBanner';
import { useCreateListingContext } from '../CreateListingContext';
import { DimensionsStepData } from '../CreateListingContextUtil';
import { StepVisitation, useCreateListingStep } from '../CreateListingHooks';

import './WeightPriceStep.scss';

const isMissingDimensionsData = ({ width, length, thickness }: DimensionsStepData) => !width || !length || !thickness;

export const WeightPriceStep = ({ stepId }: StepProps) => {
    const { t } = useTranslation('uiDomain');
    const createListingContext = useCreateListingContext();
    const { weightPriceStepData, materialStepData, dimensionsStepData } = createListingContext;
    const { product, materialType } = createListingContext.materialStepData;
    const location = useLocation();
    const { isFeatureSupported } = useFeatureFlag();
    const targetPrice = location?.state?.targetPrice;
    const targetPricingUnit = location?.state?.targetPricingUnit;
    const initialWeight = useRef(formatNumberForInput(weightPriceStepData.weight));

    const currencyCode = getCurrencyFromRegion(getRegion());

    const { formRef, refreshStepStatus, stepVisitation } = useCreateListingStep(stepId);

    // refresh status when material data changes
    useEffect(() => {
        refreshStepStatus();
    }, [product]);

    const handleWeightChange = (newWeight?: number) => {
        createListingContext.updateWeightPriceStepData({
            weight: newWeight,
        });
    };

    const handleNumberOfItemsChange = (newNumberOfItems?: number) => {
        createListingContext.updateWeightPriceStepData({
            numberOfItems: newNumberOfItems,
        });
    };

    const handleSellingPriceChange = (newSellingPrice?: MonetaryAmount) => {
        createListingContext.updateWeightPriceStepData({
            sellingPriceAmount: newSellingPrice,
            sellingPriceCurrencyCode: currencyCode,
        });
    };

    const handleExpirationTimeChange = (newExpirationTime?: Date) => {
        createListingContext.updateWeightPriceStepData({
            expirationDate: newExpirationTime,
        });
    };

    const handleAutoRenewChange = (autoRenewEnabled?: boolean) => {
        createListingContext.updateWeightPriceStepData({
            autoRenew: autoRenewEnabled,
        });
    };

    const stepVisited = stepVisitation === StepVisitation.VISITED;

    const tradeUnit = getTradeUnit(materialStepData);
    const tradeUnitCalculation = getTradeUnitCalculation(materialStepData);

    return (
        <div className="weight-price-step">
            <FormLayout>
                {tradeUnitCalculation === TradeUnitCalculation.BY_ITEM &&
                    ((stepVisited && !weightPriceStepData.weight) || isMissingDimensionsData(dimensionsStepData)) && (
                        <Notification
                            level={NotificationLevel.ERROR}
                            message={t('materialWeightInput.theoreticalWeightNotification')}
                            stayOpen
                            disableScroll
                        />
                    )}
                <form ref={formRef}>
                    <MaterialWeightInput
                        initialWeight={initialWeight.current}
                        setWeight={handleWeightChange}
                        required
                        forceValidation={stepVisited}
                        tradeUnit={tradeUnit}
                        tradeUnitCalculation={tradeUnitCalculation}
                        theoreticalWeight={weightPriceStepData.weight}
                    />
                    <NumberOfItemsInput
                        product={product}
                        materialType={materialType}
                        numberOfItems={weightPriceStepData.numberOfItems}
                        setNumberOfItems={handleNumberOfItemsChange}
                        required
                        forceValidation={stepVisited}
                    />
                    {isFeatureSupported(Feature.IMR_TARGET_PRICE) && targetPrice && (
                        <TargetPriceBanner targetPrice={targetPrice} pricingUnit={targetPricingUnit} />
                    )}
                    <MaterialPriceInput
                        priceCurrencyCode={currencyCode}
                        initialPrice={weightPriceStepData.sellingPriceAmount}
                        setPrice={handleSellingPriceChange}
                        required
                        forceValidation={stepVisited}
                        pricingUnit={getPricingUnit(materialStepData)}
                        name="sellingPrice"
                    />
                    <ExpiryTimeSelection
                        header={t('expiryTimeSelection.formItemHeader')}
                        label={t('expiryTimeSelection.label')}
                        expiryTime={weightPriceStepData.expirationDate}
                        expiryTimeChanged={handleExpirationTimeChange}
                        required
                        forceValidation={stepVisited}
                    />
                    <AutoRenewalCheckbox enabled={weightPriceStepData.autoRenew} onChange={handleAutoRenewChange} />
                </form>
            </FormLayout>
        </div>
    );
};
