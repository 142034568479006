import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getTolerances, MaterialType, Product, Tolerance, getNumberInputPattern } from '@steelbuy/ts-shared';
import {
    FormItem,
    InputRadioButton,
    InputRadioButtonGroup,
    InputRadioButtonGroupAlign,
    InputTextfieldUnit,
} from '@steelbuy/ui-primitive';

import { RequiredFieldValidationProps } from '../validation/Validation';
import { useNumberValidator, useRequiredFieldValidator } from '../validation/ValidationHooks';

const ProductsWithLength = [Product.PLATE, Product.SHEET, Product.TREAD];

export type MaterialDimensionCreateSpecification = {
    thickness?: string;
    width?: string;
    length?: string;
};

type MaterialDimensionCreateSelectionProps = {
    product: Product;
    dimensions: MaterialDimensionCreateSpecification;
    setDimensions: (dimensions: MaterialDimensionCreateSpecification) => void;
    toleranceThickness?: Tolerance;
    setToleranceThickness: (toleranceThickness?: Tolerance) => void;
    prime: boolean;
    materialType: MaterialType;
} & RequiredFieldValidationProps;

export const MaterialDimensionCreateSelection = ({
    product,
    dimensions,
    setDimensions,
    toleranceThickness,
    setToleranceThickness,
    prime,
    materialType,
    forceValidation,
}: MaterialDimensionCreateSelectionProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const { thickness, width, length } = dimensions;
    const requiredFieldValidator = useRequiredFieldValidator();
    const numberValidator = useNumberValidator();
    const availableTolerances = getTolerances(
        {
            materialType,
        },
        prime
    );

    useEffect(() => {
        if (
            (toleranceThickness && !availableTolerances.includes(toleranceThickness)) ||
            availableTolerances.length === 1
        ) {
            setToleranceThickness(availableTolerances[0]);
        }
    }, [availableTolerances]);

    const updateDimensions = (dimension: keyof MaterialDimensionCreateSpecification, value?: string) => {
        setDimensions({
            ...dimensions,
            [dimension]: value === '' ? undefined : value,
        });
    };

    useEffect(() => {
        if (length !== undefined && !ProductsWithLength.includes(product)) {
            updateDimensions('length');
        }
    }, [length, product]);

    // resetting tolerance thickness if prime changed
    useEffect(() => {
        if (prime === false && !toleranceThickness) {
            setToleranceThickness(Tolerance.NON_PRIME);
        }

        if (prime && toleranceThickness === Tolerance.NON_PRIME) {
            setToleranceThickness(undefined);
        }
    }, [prime, toleranceThickness]);

    const renderToleranceThickness = () => (
        <InputRadioButtonGroup
            align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}
            label={t('uiDomain:materialDimensionSelection.toleranceThicknessLabel')}
            value={toleranceThickness}
            required
            requiredMessage={t('uiDomain:validation.requiredField')}
            forceValidation={forceValidation}
            isMandatory
        >
            {availableTolerances.map((tolerance) => (
                <InputRadioButton
                    key={tolerance}
                    name="toleranceThickness"
                    value={t(`domainModel:material.tolerance.value.${tolerance}`)}
                    onChange={() => setToleranceThickness(tolerance)}
                    checked={toleranceThickness === tolerance}
                    required
                />
            ))}
        </InputRadioButtonGroup>
    );

    return (
        <FormItem header={t('domainModel:material.dimensions.name')}>
            <InputTextfieldUnit
                unit={t('domainModel:material.dimensions.units.millimeters')}
                label={t('domainModel:material.dimensions.thickness')}
                pattern={getNumberInputPattern()}
                name="thickness"
                value={thickness ?? ''}
                onChange={(value) => updateDimensions('thickness', value)}
                validators={[requiredFieldValidator, numberValidator]}
                forceValidation={forceValidation}
                isMandatory
            />
            {renderToleranceThickness()}
            <InputTextfieldUnit
                unit={t('domainModel:material.dimensions.units.millimeters')}
                label={t('domainModel:material.dimensions.width')}
                pattern={getNumberInputPattern()}
                name="width"
                value={width ?? ''}
                onChange={(value) => updateDimensions('width', value)}
                validators={[requiredFieldValidator, numberValidator]}
                forceValidation={forceValidation}
                isMandatory
            />
            {ProductsWithLength.includes(product) ? (
                <InputTextfieldUnit
                    unit={t('domainModel:material.dimensions.units.millimeters')}
                    label={t('domainModel:material.dimensions.length')}
                    pattern={getNumberInputPattern()}
                    name="length"
                    value={length ?? ''}
                    onChange={(value) => updateDimensions('length', value)}
                    validators={[requiredFieldValidator, numberValidator]}
                    forceValidation={forceValidation}
                    isMandatory
                />
            ) : null}
        </FormItem>
    );
};
